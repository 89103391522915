<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.ProjectInfos.Title_2}} &nbsp; <span style="font-size:1vw">{{this.$lang.Menus.FillFields}}</span>

            <p class="control" slot="right" style="margin-right: 16px">
                <saveButton v-on:callMethodInParent="setSavePayload" />
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 1.75rem; padding-top: 1.4rem">
            <div class="columns">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-2 verticalStep" style="background-color: #FFFFFF; height: 100vh; display: flex; flex-direction: column;">
                        <verticalStep-bar />
                    </div>
                    <div class="column is-10">
                        <div class="columns">
                            <div class="column is-4">
                                <section>
                                    <b-field horizontal :label="this.$lang.ProjectInfos.ProjectName" :type="{ 'is-danger': hasError_projectName }" :message="this.emptyWarning_projectName">
                                        <b-input v-model="projectName" style="width: 65%;" @input="enableManuelSave()"></b-input>
                                    </b-field>

                                    <b-field horizontal :label="this.$lang.ProjectInfos.PanelName" :type="{ 'is-danger': hasError_panelName }" :message="this.emptyWarning_panelName">
                                        <b-input v-model="panelName" style="width: 65%;" @input="enableManuelSave()"></b-input>
                                    </b-field>

                                    <b-field horizontal :label="this.$lang.ProjectInfos.PanelNo" :type="{ 'is-danger': hasError_panelNo }" :message="this.emptyWarning_panelNo">
                                        <b-input v-model="panelNo" type="number" min="0" style="width: 65%;" @input="setPanelNoValidity(); enableManuelSave();"></b-input>
                                    </b-field>
                                </section>
                            </div>

                            <div class="column is-4">
                                <b-field :type="{ 'is-danger': hasError_ratedCurrent }" :message="this.emptyWarning_ratedCurrent">
                                    <template #label>
                                        <span v-html="$lang.ProjectInfos.RatedCurrent"></span>
                                    </template>
                                    <div>
                                        <b-field>
                                            <b-radio v-model="currentRadio"
                                                     native-value="value_1"
                                                     @input="setMultiEyed(); enableManuelSave();">
                                            </b-radio>
                                            <div>
                                                <span v-html="this.currentslist[0]"></span>
                                            </div>
                                            <p class="px-3">

                                            </p>
                                        </b-field>
                                        <b-field>
                                            <b-radio v-model="currentRadio"
                                                     native-value="value_2"
                                                     @input="setMultiEyed(); enableManuelSave();">
                                            </b-radio>
                                            <div>
                                                <span v-html="this.currentslist[1]"></span>
                                            </div>
                                            <p class="px-3">

                                            </p>
                                        </b-field>
                                        <b-field>
                                            <b-radio v-model="currentRadio"
                                                     native-value="value_3"
                                                     @input="setMultiEyed(); enableManuelSave();">
                                            </b-radio>
                                            <div>
                                                <span v-html="this.currentslist[2]"></span>
                                            </div>
                                            <p class="px-3">

                                            </p>
                                        </b-field>
                                    </div>
                                </b-field>

                                <section class="section is-main-section" v-if="isMultiEyed">
                                    <b-field class="field is-grouped">
                                        <b-field :type="{ 'is-danger': hasError_totalEyeNumber }" :message="this.emptyWarning_totalEyeNumber">
                                            {{ this.$lang.ProjectInfos.TotalEyesNumber }}
                                            <b-input v-model="totalEyesNumber" type="number" min="0" step="1" style="width: 35%;" @input="setEyesNumberValidity(); enableManuelSave()"></b-input>
                                        </b-field>

                                        <b-field :type="{ 'is-danger': hasError_eyeNumber_2, 'is-danger': hasError_eyeNumber }" :message="{ 'Göz numarası toplam göz sayısından büyük olamaz!': hasError_eyeNumber,
                                                            'Alan Boş Bırakılamaz!': hasError_eyeNumber_2}">
                                            {{ this.$lang.ProjectInfos.EyeNumber }}
                                            <b-input v-model="eyesNumber" type="number" min="0" step="1" style="width: 35%;" @input="setEyesNumberValidity(); enableManuelSave()"></b-input>
                                        </b-field>
                                    </b-field>
                                </section>

                            </div>
                        </div>

                        <div class="card-content" style="width: 75%">
                            <div> <b> <span v-html="this.$lang.ProjectInfos.Title"></span> </b> </div>
                            <div> <span v-html="this.$lang.ProjectInfos.Info_1"></span> </div>
                            <div> <span v-html="this.$lang.ProjectInfos.Info_2"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_3"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_4"></span> </div>
                            <div> <span v-html="this.$lang.ProjectInfos.Info_5"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_6"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_7"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_8"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_9"></span> </div>
                            <div style="padding-left: 1.25rem"> <span v-html="this.$lang.ProjectInfos.Info_10"></span> </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import SaveButton from '@/components/SaveButton.vue';

    export default {
        name: 'ProjectInfos',
        components: {
            VerticalStepBar,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                isInfoOpened: false,
                projectName: '',
                panelName: '',
                panelNo: 0,
                currentslist: [],
                selectedRatedCurrent: 0,
                currentRadio: '',
                currentRadios: ['value_1', 'value_2', 'value_3'],
                totalEyesNumber: 0,
                eyesNumber: 0,
                isMultiEyed: false,
                canGoNextPage: false,

                hasError_projectName: false,
                emptyWarning_projectName: '',
                hasError_panelName: false,
                emptyWarning_panelName: '',
                hasError_panelNo: false,
                emptyWarning_panelNo: '',
                hasError_ratedCurrent: false,
                emptyWarning_ratedCurrent: '',
                hasError_totalEyeNumber: false,
                emptyWarning_totalEyeNumber: '',
                hasError_eyeNumber: false,
                hasError_eyeNumber_2: false
            }
        },
        mounted() {
            this.currentslist = [];
            this.currentslist.push(this.$lang.ProjectInfos.CurrentVal_1);
            this.currentslist.push(this.$lang.ProjectInfos.CurrentVal_2);
            this.currentslist.push(this.$lang.ProjectInfos.CurrentVal_3);

            this.selectedRatedCurrent = this.currentslist[this.$store.state.ratedCurrentIndex - 1];
            this.currentRadio = this.currentRadios[this.$store.state.ratedCurrentIndex - 1];
            this.setMultiEyed();

            this.projectName = this.$store.state.projectName;
            this.panelName = this.$store.state.panelName;
            this.panelNo = this.$store.state.panelNo;
            this.totalEyesNumber = this.$store.state.totalEyesNumber;
            this.eyesNumber = this.$store.state.eyesNumber;
            this.$store.state.activeStep = 0;
            this.$store.state.isFooterFixed = true;
        },
        methods: {
            submit() {
                this.controlInputs();

                if (this.canGoNextPage) {
                    this.setSavePayload();

                    this.$store.isPageOpenedFromStepBar = false;
                    this.$store.canPanelInfosOpen = true;
                    router.push('/panelInfos');
                }
            },
            enableManuelSave() {
                this.$store.state.canManuelSave = true;
            },
            setSavePayload() {
                var i = this.currentslist.indexOf(this.selectedRatedCurrent);
                i += 1;
                var payload = { langCode: "tr-TR", projectName: this.projectName, panelName: this.panelName, panelNo: this.panelNo, ratedCurrentIndex: i, panelRatedCurrent: this.selectedRatedCurrent, totalEyesNumber: this.totalEyesNumber, eyesNumber: this.eyesNumber };
                this.$store.commit('setProjectInfos', payload);
            },
            setPanelNoValidity() {
                if (this.panelNo < 0) {
                    this.panelNo = 0;
                }

                var value = (this.panelNo - Math.floor(this.panelNo)) !== 0;
                if (value) {
                    this.panelNo = 0;
                }
            },
            setMultiEyed() {
                var i = this.currentRadios.indexOf(this.currentRadio);
                this.selectedRatedCurrent = this.currentslist[i];
                if (i == 2) {
                    this.isMultiEyed = true;
                } else {
                    this.isMultiEyed = false;
                }
            },
            setEyesNumberValidity() {
                if (this.eyesNumber < 0) {
                    this.eyesNumber = 0;
                }
                if (this.totalEyesNumber < 0) {
                    this.totalEyesNumber = 0;
                }
                var value1 = (this.eyesNumber - Math.floor(this.eyesNumber)) !== 0;
                if (value1) {
                    this.eyesNumber = 0;
                }
                var value2 = (this.totalEyesNumber - Math.floor(this.totalEyesNumber)) !== 0;
                if (value2) {
                    this.totalEyesNumber = 0;
                }

                if (this.eyesNumber > this.totalEyesNumber) {
                    this.hasError_eyeNumber = true;
                } else {
                    this.hasError_eyeNumber = false;
                }
                this.hasError_eyeNumber_2 = false;
            },
            controlInputs() {
                var isOtherInputsEntered = false;
                if (this.projectName == '') {
                    this.hasError_projectName = true;
                    this.emptyWarning_projectName = this.$lang.Menus.EmptyWarning;
                } else if (this.panelName == '') {
                    this.hasError_panelName = true;
                    this.emptyWarning_panelName = this.$lang.Menus.EmptyWarning;
                } else if (this.panelNo == '') {
                    this.hasError_panelNo = true;
                    this.emptyWarning_panelNo = this.$lang.Menus.EmptyWarning;
                } else if (this.selectedRatedCurrent == null) {
                    this.hasError_ratedCurrent = true;
                    this.emptyWarning_ratedCurrent = this.$lang.Menus.EmptyWarning;
                } else {
                    isOtherInputsEntered = true;
                    if (!this.isMultiEyed) {
                        this.canGoNextPage = true;
                    }
                }

                if (this.isMultiEyed) {
                    if (this.totalEyesNumber == '') {
                        this.hasError_totalEyeNumber = true;
                        this.emptyWarning_totalEyeNumber = this.$lang.Menus.EmptyWarning;
                    } else if (this.eyesNumber > this.totalEyesNumber) {
                        this.hasError_eyeNumber = true;
                    } else if (this.eyesNumber == '') {
                        this.hasError_eyeNumber_2 = true;
                    } else {
                        if (isOtherInputsEntered) {
                            this.canGoNextPage = true;
                        }
                    }
                }

                if (this.projectName != '') {
                    this.hasError_projectName = false;
                    this.emptyWarning_projectName = "";
                }
                if (this.panelName != '') {
                    this.hasError_panelName = false;
                    this.emptyWarning_panelName = "";
                }
                if (this.panelNo != '') {
                    this.hasError_panelNo = false;
                    this.emptyWarning_panelNo = "";
                }
                if (this.selectedRatedCurrent != null) {
                    this.hasError_ratedCurrent = false;
                    this.emptyWarning_ratedCurrent = "";
                }
                if (this.totalEyesNumber != '') {
                    this.hasError_totalEyeNumber = false;
                    this.emptyWarning_totalEyeNumber = "";
                }
                if (this.eyesNumber != '' && this.eyesNumber <= this.totalEyesNumber) {
                    this.hasError_eyeNumber = false;
                    this.emptyWarning_ratedCurrent = "";
                }
            },
            openInfo() {
                if (!this.isInfoOpened) {
                    this.isInfoOpened = true;
                } else {
                    this.isInfoOpened = false;
                }
            }
        }
    };
</script>

<style>
    @media only screen and (max-width: 1024px) {
        .verticalStep {
            font-size: 2vw !important;
        }
    }
</style>
