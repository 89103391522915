<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.CalcCircInfos.Title_1}} &nbsp; <span style="font-size:1vw">{{this.$lang.CalcCircInfos.Title_2}}</span>

            <p class="control" slot="right" style="margin-right: 16px">
                <saveButton />
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="is-warning" @click="openCircuitDevices" style="height: 88%;">
                    {{this.$lang.Calculation.InnerTitle_14}} <br /> {{this.$lang.Calculation.InnerTitle_15}} <br />{{this.$lang.Calculation.InnerTitle_16}}
                </b-button>
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 0.75rem; padding-bottom: 1rem">
            <div class="columns is-multiline is-mobile">
                <div class="column is-1" style="background-color: #FFFFFF; width: 10%; height: 100vh; display: flex; flex-direction: column;">
                    <verticalStep-bar />
                </div>
                <div class="column">
                    <calculationTabs />
                    <div style="padding: 0.5rem;">
                        <outputCircBtns />
                    </div>

                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="column">
                            <!--<b-field :label="this.$lang.CalcCircInfos.Title_3"></b-field>-->
                            <span style="font-size:1vw"> {{ this.$lang.CalcCircInfos.Title_3 }} </span>
                            <div style="padding: 0.5rem;"></div>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.TableDefinition" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.TableDefinition">
                                        <b-input v-model="definition_1"></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.BusbarDimension" :label-position="labelPosition" :type="{ 'is-danger': hasError_SelectedBusbarDimension_1 }" :message="this.emptyWarning_SelectedBusbarDimension_1">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.BusbarDimension">
                                        <b-select v-if="busbarDimensionList" v-model="selectedBusbarDimension_1" @input="getBusbarCalculationDevice">
                                            <option v-for="option in busbarDimensionList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 10%;" :type="{ 'is-danger': hasError_ConductorLength_1 }" :message="this.emptyWarning_ConductorLength_1">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                        <b-numberinput v-model="conductorLength_1" :controls=false step="any" @input="setPositiveNumber()"></b-numberinput>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                        <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>

                                <b-field :label="this.$lang.CalcCircInfos.Imax" :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-input v-model="Imax_1" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                        <b-input v-model="phaseNumber_1" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                        <b-input v-model="lostPower_1" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <p class="control">
                                    <b-button type="is-success" :label="this.$lang.CalcCircInfos.CalcAddButton" @click="addToBusbarCalculationDevices" />
                                </p>
                            </b-field>

                            <div v-if="busbardevicesdata.length != 0">
                                <b-field :label="this.$lang.CalcCircInfos.Title_7" style="padding-top: 2rem;"></b-field>
                                <b-table :data="busbardevicesdata">

                                    <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="250">
                                        {{ props.row.Definition }}
                                    </b-table-column>
                                    <b-table-column field="Width_Thickness" :label="this.$lang.CalcCircInfos.BusbarDimension" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.Width_Thickness }}
                                    </b-table-column>
                                    <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.ConductorTemperature }}
                                    </b-table-column>
                                    <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" width="150">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ props.row.MaxTemperature }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="I_Max" :td-attrs="columnTdAttrs" width="150">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ truncate(props.row.I_Max, 1) }}
                                        </template>
                                    </b-table-column>
                                    <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.ConductorLength }}
                                    </b-table-column>
                                    <b-table-column field="PhasesNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ props.row.PhasesNumber }}
                                    </b-table-column>
                                    <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                        {{ truncate(props.row.LostPower, 1) }}
                                    </b-table-column>
                                    <b-table-column field="CalcResult" :td-attrs="columnTdAttrs" width="150">
                                        <template v-slot:header="">
                                            <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ truncate(props.row.CalcResult, 1) }}
                                        </template>
                                    </b-table-column>

                                    <b-table-column field="Action" v-slot="props">
                                        <button class="button is-small is-light" @click="openUpdateBusbarDeviceModal(props.row)">
                                            <b-icon icon="pen" size="is-small"></b-icon>
                                        </button>
                                        <button class="button is-small is-danger" @click="deleteFromBusbarCalculationDevices(props.row)">
                                            <b-icon icon="delete" size="is-small"></b-icon>
                                        </button>
                                        <button class="button is-small is-light" @click="copyFromBusbarCalculationDevices(props.row)" style="width: 20px">
                                            <div class="material-symbols-outlined">
                                                content_copy
                                            </div>
                                            <!--<b-icon pack="content_copy" icon="file" size="is-small"></b-icon>-->
                                        </button>
                                    </b-table-column>
                                </b-table>
                                <div style="padding: 0.5rem;">
                                    <div class="columns is-mobile is-multiline">
                                        <div class="field is-grouped">
                                            <b-field>
                                                <b-field :label="this.$lang.CalcCircInfos.Total_P_Busbar"></b-field>
                                            </b-field>
                                            <b-field>
                                                {{ this.truncate(total_P_Busbar, 1) }}
                                            </b-field>
                                        </div>
                                    </div>
                                </div>

                                <div style="padding-top: 2rem"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal v-model="isBusbarDeviceUpdateModalActive" :width="960">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDevice }}</p>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.TableDefinition" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.TableDefinition">
                                            <b-input v-model="definition_1"></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.BusbarDimension" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.BusbarDimension">
                                            <b-select v-if="busbarDimensionList" v-model="selectedBusbarDimension_1" @input="getBusbarCalculationDevice">
                                                <option v-for="option in busbarDimensionList"
                                                        :value="option"
                                                        :key="option.id">
                                                    {{ option }}
                                                </option>
                                            </b-select>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                            <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label-position="labelPosition" style="width: 155px">
                                        <template #label>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-tooltip>
                                            <template v-slot:content>
                                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                            </template>
                                            <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label-position="labelPosition" style="width: 155px">
                                        <template #label>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-tooltip>
                                            <template v-slot:content>
                                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                                            </template>
                                            <b-input v-model="Imax_1" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                            <b-numberinput v-model="conductorLength_1" :controls=false step="any" @input="setPositiveNumber()"></b-numberinput>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                            <b-input v-model="phaseNumber_1" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                            <b-input v-model="lostPower_1" type="number" step="any" disabled></b-input>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>

                            </div>
                        </div>
                        <div class="content">
                            <footer class="modal-card-foot">
                                <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                          @click="isBusbarDeviceUpdateModalActive = false" />
                                <b-button :label="this.$lang.Menus.ModalUpdtBtn"
                                          type="is-primary"
                                          @click="updateBusbarCalculationDevice()" />
                            </footer>
                        </div>
                    </div>
                </div>
            </b-modal>

        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import OutputCircBtns from '@/components/OutputCircBtns.vue';
    import CalculationTabs from '@/components/CalculationTabs.vue';
    import SaveButton from '@/components/SaveButton.vue';

    const DIGIT_EXPRESSION = /^\d$/;

    const isDigit = (character) => {
        return character && DIGIT_EXPRESSION.test(character);
    };

    export default {
        name: 'Calc_Busbar',
        components: {
            VerticalStepBar,
            OutputCircBtns,
            CalculationTabs,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                labelPosition: 'on-border',
                projectId: 0,
                currentPage: 1,
                busbardevicesdata: [],
                total_P_Busbar: 0,

                busbarCalcDeviceId: 0,
                definition_1: this.$lang.Calculation.Busbar,
                definitionIndex_1: 0,
                busbarDimensionList: [],
                selectedBusbarDimension_1: '',
                conductorTemp: 70,
                Tmax_inPanel: 0,
                Imax_1: 0,
                conductorLength_1: 1,
                phaseNumber_1: 3,
                lostPower_1: 0,
                isBusbarDeviceUpdateModalActive: false,
                hasError_SelectedBusbarDimension_1: false,
                emptyWarning_SelectedBusbarDimension_1: '',
                hasError_ConductorLength_1: false,
                emptyWarning_ConductorLength_1: '',
                canCalculate_1: false,
            }
        },
        mounted() {
            this.projectId = this.$store.state.projectId;
            this.Tmax_inPanel = this.$store.state.maxTemperature;

            this.getBusbarDevicesTable();
            this.getBusbarDimensions();
            this.$store.state.activeStep = 3;
            this.$store.state.isFooterFixed = false;
        },
        watch: {
            $route() {

            }
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/envConditions');
            },
            submit() {
                this.saveResult();

                this.$store.isPageOpenedFromStepBar = false;
                this.$store.canResultOpen = true;

                router.push('/calc_InputCircuit');
            },
            saveResult() {
                var totalP = this.$store.state.P_InputCircuit + this.$store.state.P_OutputCircuit + this.$store.state.P_ManuelCircuit + this.total_P_Busbar;
                var payload = { BusbarDef: this.definition_1 + " " + this.definitionIndex_1, P_Busbar: this.total_P_Busbar, totalLostPower_3: totalP };
                this.$store.commit('set_Datas_Busbar', payload);
            },
            resetBusbarDevice() {
                this.selectedBusbarDimension_1 = '';
                this.conductorTemp = 70;
                this.Imax_1 = 0;
                this.conductorLength_1 = 1;
                this.phaseNumber_1 = 3;
                this.lostPower_1 = 0;
                this.isBusbarDeviceUpdateModalActive = false;
            },
            getTotal_P_BusbarCalculationDevices() {
                api.panels.getTotal_P_BusbarCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_Busbar = res.data;
                        this.saveResult();
                    }
                });
            },
            getBusbarDevicesTable() {
                this.busbardevicesdata = [];
                api.panels.getBusbarCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        var digitList = [];
                        res.data.forEach((e) => {
                            this.busbardevicesdata.push(e);

                            var last = e.Definition.charAt(e.Definition.length - 1);
                            if (isDigit(last)) {
                                digitList.push(parseInt(last));
                            }
                        });
                        this.getTotal_P_BusbarCalculationDevices();

                        var maxDigit = 0;
                        for (let i = 0; i < digitList.length; i++) {
                            if (maxDigit < digitList[i]) {
                                maxDigit = digitList[i];
                            }
                        }
                        this.definitionIndex_1 = maxDigit + 1;
                        this.definition_1 = this.$lang.Calculation.Busbar + " " + this.definitionIndex_1;
                    }
                });
            },
            addToBusbarCalculationDevices() {
                this.controlInputs();

                if (this.canCalculate_1) {
                    const device = {
                        ProjectId: this.projectId,
                        Definition: this.definition_1,
                        Width_Thickness: this.selectedBusbarDimension_1,
                        ConductorTemperature: this.conductorTemp,
                        MaxTemperature: this.Tmax_inPanel,
                        I_Max: this.Imax_1,
                        ConductorLength: this.conductorLength_1,
                        PhasesNumber: this.phaseNumber_1,
                        LostPower: this.lostPower_1,
                    };

                    api.panels.addToBusbarCalculationDevices(device).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                            this.getBusbarDevicesTable();
                            this.resetBusbarDevice();
                        }
                    });
                }
            },
            deleteFromBusbarCalculationDevices(device) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.deleteFromBusbarCalculationDevices(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getBusbarDevicesTable();
                            }
                        })
                })
            },
            copyFromBusbarCalculationDevices(device) {
                var initialDefinition = device.Definition;
                device.Definition = device.Definition + " " + this.$lang.CopyDialog.Extension;
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToBusbarCalculationDevices(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                                this.getBusbarDevicesTable();
                            }
                        }),
                    onCancel: () => {
                        device.Definition = initialDefinition;
                    }
                })
            },
            openUpdateBusbarDeviceModal(device) {
                this.busbarCalcDeviceId = device.Id;
                this.isBusbarDeviceUpdateModalActive = true;
                this.definition_1 = device.Definition;
                this.selectedBusbarDimension_1 = device.Width_Thickness;
                this.Imax_1 = device.I_Max;
                this.conductorLength_1 = device.ConductorLength;
                this.phaseNumber_1 = device.PhasesNumber;
                this.lostPower_1 = device.LostPower;
            },
            updateBusbarCalculationDevice() {
                const device = {
                    Id: this.busbarCalcDeviceId,
                    ProjectId: this.projectId,
                    Definition: this.definition_1,
                    Width_Thickness: this.selectedBusbarDimension_1,
                    ConductorTemperature: this.conductorTemp,
                    MaxTemperature: this.Tmax_inPanel,
                    I_Max: this.Imax_1,
                    ConductorLength: this.conductorLength_1,
                    PhasesNumber: this.phaseNumber_1,
                    LostPower: this.lostPower_1,
                };

                api.panels.updateBusbarCalculationDevice(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isBusbarDeviceUpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                        this.getBusbarDevicesTable();
                    }
                });
            },
            getBusbarDimensions() {
                this.busbarDimensionList = [];
                api.panels.getBusbarDimensions().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.busbarDimensionList.push(e);
                        });
                    }
                });
            },
            getBusbarCalculationDevice() {
                var busbarIndex = this.busbarDimensionList.indexOf(this.selectedBusbarDimension_1) + 1;
                api.panels.getBusbarCalculationDevice(busbarIndex, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.Imax_1 = res.data.I_Max;
                        this.lostPower_1 = res.data.LostPower;
                    }
                });
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            controlInputs() {
                if (this.selectedBusbarDimension_1 == '') {
                    this.hasError_SelectedBusbarDimension_1 = true;
                    this.emptyWarning_SelectedBusbarDimension_1 = this.$lang.Menus.EmptyWarning;
                } else if (this.conductorLength_1 == 0) {
                    this.hasError_ConductorLength_1 = true;
                    this.emptyWarning_ConductorLength_1 = this.$lang.Menus.EmptyWarning;
                } else {
                    this.canCalculate_1 = true;
                }

                if (this.selectedBusbarDimension_1 != '') {
                    this.hasError_SelectedBusbarDimension_1 = false;
                    this.emptyWarning_SelectedBusbarDimension_1 = '';
                }
                if (this.conductorLength_1 != 0) {
                    this.hasError_ConductorLength_1 = false;
                    this.emptyWarning_ConductorLength_1 = '';
                }
            },
            openCircuitDevices() {
                this.$store.state.previousRoute = this.$route.name;
                this.$store.state.isCircDevicesFromCalculation = true;
                console.log("debug " + this.$store.state.isAdmin);
                if (this.$store.state.isAdmin == 1) {
                    router.push('/circuitDevices_Admin');
                } else {
                    router.push('/circuitDevices');
                }
            }
        }
    };
</script>

<style>
    .material-symbols-outlined {
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
</style>
