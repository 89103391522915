<template>
       <b-steps v-model="activeStep" @input="openNewPage()" :has-navigation="hasNavigation" vertical>
        <b-step-item step="1" :label="this.$lang.StepBar.ProjectInfos" :clickable="true">

        </b-step-item>

        <b-step-item step="2" :label="this.$lang.StepBar.PanelInfos" :clickable="canPanelInfosOpen">

        </b-step-item>

        <b-step-item step="3" :label="this.$lang.StepBar.EnvConds" :clickable="canEnvConditionsOpen">

        </b-step-item>

        <b-step-item :step="4" :label="this.$lang.StepBar.LostPower" :clickable="canCalculationOpen">

        </b-step-item>

        <b-step-item :step="5" :label="this.$lang.StepBar.Result" :clickable="canResultOpen">

        </b-step-item>

        <b-step-item :step="6" :label="this.$lang.StepBar.Report" :clickable="canReportPageOpen">

        </b-step-item>

        <!--<b-step-item :step="7" :label="this.$lang.StepBar.FanCalc" :clickable="canFanCalcOpen">

</b-step-item>-->

        <template v-if="false"
                  #navigation="{previous, next}">
            <b-button outlined
                      type="is-danger"
                      icon-pack="fas"
                      icon-left="backward"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                Previous
            </b-button>
            <b-button outlined
                      type="is-success"
                      icon-pack="fas"
                      icon-right="forward"
                      :disabled="next.disabled"
                      @click.prevent="next.action">
                Next
            </b-button>
        </template>
    </b-steps>
    
</template>

<script>
    import router from '@/router';

    export default {
        name: 'Main',
        components: {
        },
        data() {
            return {
                isStepBarActive: true,
                hasNavigation: false,
                activeStep: 0,
                isPageOpenedFromStepBar: false,
                canPanelInfosOpen: false,
                canEnvConditionsOpen: false,
                canCalculationOpen: false,
                canResultOpen: false,
                canReportPageOpen: false,
                // canFanCalcOpen: false
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        mounted() {
             this.setCurrentPage();
        },
        updated() {
            this.activeStep = this.$store.state.activeStep;
        },
        watch: {
            $route() {
                 this.setCurrentPage();
            }
        },
        methods: {           
            setCurrentPage() {
                this.activeStep = this.$store.state.activeStep;
                if (this.$route.name == "projectInfos" || this.$route.name == "panelInfos" || this.$route.name == "envConditions" || this.$route.name == "calculation" ||
                    this.$route.name == "calc_InputCircuit" || this.$route.name == "calc_OutputCircuit" || this.$route.name == "calc_Manuel" ||
                    this.$route.name == "results" || this.$route.name == "reportPage" || this.$route.name == "fan_Calc") {
                    this.isStepBarActive = true;
                } else if (this.$route.name == "mainMenu" || this.$route.name == "circuitDevices" || this.$route.name == "myProjects" || this.$route.name == "allProjects" || this.$route.name == "newProject" ||
                    this.$route.name == "addMember" || this.$route.name == "updateMember" || this.$route.name == "loginMember") {
                    this.isStepBarActive = false;
                }

                this.canPanelInfosOpen = this.$store.canPanelInfosOpen;
                this.canEnvConditionsOpen = this.$store.canEnvConditionsOpen;
                this.canCalculationOpen = this.$store.canCalculationOpen;
                this.canResultOpen = this.$store.canResultOpen;
                this.canReportPageOpen = this.$store.canReportPageOpen;
                // this.canFanCalcOpen = this.$store.canFanCalcOpen;
            },
            openNewPage() {
                this.$store.isPageOpenedFromStepBar = true;

                if (this.activeStep == 0) {
                    router.push('/ProjectInfos');
                } else if (this.activeStep == 1) {
                    router.push('/PanelInfos');
                } else if (this.activeStep == 2) {
                    router.push('/EnvConditions');
                } else if (this.activeStep == 3) {
                    router.push('/Calc_Summary');
                } else if (this.activeStep == 4) {
                    router.push('/Results');
                } else if (this.activeStep == 5) {
                    router.push('/ReportPdfPage');
                } /* else if (this.activeStep == 6) {
                    router.push('/Fan_Calc');
                } */
            }
        }
    };
</script>

<style>
    .b-steps.is-vertical > .steps:not(.has-label-right):not(.has-label-left) .step-items .step-item .step-link > .step-details {
        background-color: #ffffff;
    }

    .step-item {

    }
</style>