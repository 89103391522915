<template>
	<b-table
		:loading="isLoading"
		:paginated="paginated"
		:per-page="perPage"
		:striped="true"
		:hoverable="true"
		:data="tableData"
		sort-icon="chevron-up"
		sort-icon-size=""
		:default-sort="defaultSort"
	>
		<slot></slot>
		<section slot="empty" class="section">
			<div class="content has-text-grey has-text-centered">
				<template v-if="isLoading">
					<p>
						<b-icon icon="dots-horizontal" size="is-large" />
					</p>
					<p>Yükleniyor...</p>
				</template>
				<template v-else>
					<p>
						<b-icon icon="emoticon-sad" size="is-large" />
					</p>
					<p>Kayıt Bulunamadı&hellip;</p>
				</template>
			</div>
		</section>
		<template #bottom-left>
			<b-tooltip label="Kayıt Göster" position="is-right" always type="is-light">
				<b-select v-model="perPage">
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="15">15</option>
					<option value="20">20</option>
				</b-select>
			</b-tooltip>
		</template>
	</b-table>
</template>

<script>
export default {
	props: {
		tableData: {
			type: Array,
			required: true
		},
		isLoading: {
			type: Boolean,
			require: true
		},
		defaultSort: {
			type: String
		}
	},
	data() {
		return {
			perPage: 10,
			paginated: false
		};
	},
	watch: {
		tableData() {
			if (this.tableData.length > this.perPage) {
				this.paginated = true;
			}
		}
	}
};
</script>
