import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
	plugins: [
		createPersistedState({
			storage: window.localStorage
		})
	],
	state: {
		userInfo: {},
		isNavBarVisible: true,
		isFooterBarVisible: true,
		isFooterFixed: false,
		isAsideVisible: true,
		isAsideMobileExpanded: false,
		token: '',
		deneme: {},
		currentRoute: '',
		previousRoute: '',
		projectId: 0,
		prevProjectId: 0,
		memberId: '',
		isAdmin: 0,
		canManuelSave: false,
		isCircDevicesFromCalculation: false,
		isPrevProject: false,
		calcIndex: 1,
		circIndex: 1,
		isResultValid: false,
		activeStep: 0,
		isPageOpenedFromStepBar: false,
		calcIndexFromOutputCircs: -1,
		outputCircCalcBtnIndex: 0,
		currentRouteFromOutputCircs: '',
		canPanelInfosOpen: false,
		canEnvConditionsOpen: false,
		canCalculationOpen: false,
		canResultOpen: false,
		canReportPageOpen: false,
		canFanCalcOpen: false
	},
	mutations: {
		basic(state, payload) {
			state[payload.key] = payload.value;
		},
		user(state, payload) {
			state.userInfo = payload;
			// state.token = payload.Token;
		},
		rememberMeData(state, payload) {
			state.rememberMe = payload.rememberMe;
			state.rememberMail = payload.rememberMail;
			state.rememberPassword = payload.rememberPassword;
		},
		passwordReset(state, payload) {
			state.resetEmail = payload.resetEmail;
		},
		asideMobileStateToggle(state, payload = null) {
			const htmlClassName = 'has-aside-mobile-expanded';

			let isShow;

			if (payload !== null) {
				isShow = payload;
			} else {
				isShow = !state.isAsideMobileExpanded;
			}

			if (isShow) {
				document.documentElement.classList.add(htmlClassName);
			} else {
				document.documentElement.classList.remove(htmlClassName);
			}

			state.isAsideMobileExpanded = isShow;
		},
		logout(state, string) {
			const temp = {
				Name: string		
			};
			state.userInfo = temp;
			state.token = '';
		},
		setProjectInfos(state, payload) {
			state.langCode = payload.langCode;
			state.projectName = payload.projectName;
			state.panelName = payload.panelName;
			state.panelNo = payload.panelNo;
			state.ratedCurrentIndex = payload.ratedCurrentIndex;
			state.panelRatedCurrent = payload.panelRatedCurrent;
			state.totalEyesNumber = payload.totalEyesNumber;
			state.eyesNumber = payload.eyesNumber;
			// state.deneme = payload;
		},
		setPanelInfos(state, payload) {
			state.panelType = payload.panelType;
			state.panelTypeIndex = payload.panelTypeIndex;
			state.panelDimension = payload.panelDimension;
			state.selectedIP_Id = payload.selectedIP_Id;
			state.panelIP = payload.panelIP;
			state.Sair = payload.Sair;
			state.surfaceExplanation = payload.surfaceExplanation;
			state.virtualSurface = payload.virtualSurface;
			state.virtualSurfaceIndex = payload.virtualSurfaceIndex;
			state.virtualSurfaceOption = payload.virtualSurfaceOption;
			state.seperationNumber = payload.seperationNumber;
			state.ae_value = payload.ae_value;
			state.ae_valueList = payload.ae_valueList;
		},
		setEnvConditionDatas(state, payload) {
			state.envTemperature = payload.envTemperature;
			state.maxTemperature = payload.maxTemperature;
			state.humidity = payload.humidity;
			state.altitude = payload.altitude;
		},
		setCalculationDatas(state, payload) {
			state.totalLostPower_1 = payload.totalLostPower_1;
		},
		setCalculationDatas_2(state, payload) {
			state.inPanelTemperature = payload.inPanelTemperature;
			state.envMeasureTemperature = payload.envMeasureTemperature;
			state.totalLostPower_2 = payload.totalLostPower_2;
		},
		setCalculationDatas_3(state, payload) {
			state.totalLostPower_3 = payload.totalLostPower_3;
		},
		set_Datas_Busbar(state, payload) {
			state.BusbarDef = payload.BusbarDef;
			state.P_Busbar = payload.P_Busbar;
			state.totalLostPower_3 = payload.totalLostPower_3;
		},
		set_Datas_InputCircuits(state, payload) {
			state.InputCircuitDef = payload.InputCircuitDef;
			state.P_InputCircuit = payload.P_InputCircuit;
			state.totalLostPower_3 = payload.totalLostPower_3;
		},
		set_Datas_OutputCircuits(state, payload) {
			state.OutputCircuitDef = payload.OutputCircuitDef;
			state.P_OutputCircuit = payload.P_OutputCircuit;
			state.totalLostPower_3 = payload.totalLostPower_3;
		},
		set_Datas_ManuelCircuits(state, payload) {
			state.ManuelCircuitDef = payload.ManuelCircuitDef;
			state.P_ManuelCircuit = payload.P_ManuelCircuit;
			state.totalLostPower_3 = payload.totalLostPower_3;
		},
		setResultInfos_630A(state, payload) {
			state.P_890 = payload.P_890;
			state.P_Total = payload.P_Total;
			state.result_630A = payload.result_630A;
		},
		setResultInfos_1600A(state, payload) {
			state.value_k = payload.value_k;
			state.n_value = payload.n_value;
			state.value_d = payload.value_d;
			state.value_x = payload.value_x;
			state.deltaT_0_5 = payload.deltaT_0_5;
			state.Ab_val = payload.Ab_val;
			state.height_val = payload.height_val;
			state.value_f = payload.value_f;
			state.value_g = payload.value_g;
			state.value_c = payload.value_c;
			state.deltaT_0_75 = payload.deltaT_0_75;
			state.deltaT_1 = payload.deltaT_1;
			state.result_1600A = payload.result_1600A;
		}
	},
	actions: {
		asideDesktopOnlyToggle(store, payload = null) {
			let method;

			switch (payload) {
				case true:
					method = 'add';
					break;
				case false:
					method = 'remove';
					break;
				default:
					method = 'toggle';
			}
			document.documentElement.classList[method]('has-aside-desktop-only-visible');
		}
	},
	getters: {
		hasPermission: (state) => (permission) => {
			if (state.userInfo.Permissions) {
				return state.userInfo.Permissions.includes(permission);
			} else {
				return false;
			}
		}
	}
});

export default store;

export function useStore() {
	return store;
}
