<template>
	<section class="hero is-hero-bar">
		<div class="hero-body">
			<div class="level herolevel">
				<div class="level-left">
					<div class="level-item">
						<a class="title">
							<b-icon :icon="menuIcon" size="is-small" class="mx-4"></b-icon>
							<slot />
						</a>
					</div>
				</div>
				<div v-show="hasRightVisible" class="level-right">
					<div class="level-item">
						<slot name="right" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'HeroBar',
		props: {
			hasRightVisible: {
				type: Boolean,
				default: true
			},
			menuIcon: {
				type: String,
				required: true
			}
		}
	};

</script>

<style>
	.herolevel {
		display: flex;
	}
	.level-right {
		margin: 0;
	}
</style>
