/* Styles */
import '@/scss/main.scss';

/* Core */
import Vue from 'vue';
import Buefy from 'buefy';
import VueCompositionAPI from '@vue/composition-api';
import Default from '@/layouts/Default.vue';
import Login from '@/layouts/Login.vue';
import Error from '@/layouts/Error.vue';
import Main from '@/layouts/Main.vue';
import helpers from '@/mixins/helpers.js';
import monthArray from '@/mixins/monthArray';
import api from '@/api';

/* Filters */
import filterMixins from '@/mixins/filters.js';
import Cleave from 'cleave.js';

/* Global Components */
import HeroBar from '@/components/HeroBar.vue';
import CardComponent from '@/components/CardComponent.vue';
import CustomTable from '@/components/CustomTable.vue';
import SaveButton from '@/components/BackSaveButtons.vue';
import EditButton from '@/components/EditButton.vue';

/* Router & Store */
import router from './router';
import store from './store';

/* Vue Main component */
import App from './App.vue';

/* Plugins */
import { Modals } from './plugins/modals.js';

/* SignalR */
import signalr from '@/signalr';

Vue.use(Modals);

Vue.use(signalr, process.env.VUE_APP_SIGNALR_ROOT);

Vue.config.productionTip = false;

/* Composition API */
Vue.use(VueCompositionAPI);

Vue.use(Buefy, {
    defaultDayNames: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
    defaultMonthNames: monthArray,
    defaultFirstDayOfWeek: 1,
    defaultDateFormatter: (date) => {
        return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
    },
    defaultDateParser: (date) => {
        var strDate = date.split('-');

        return new Date(strDate[2], Number.parseInt(strDate[1]) - 1, strDate[0]);
    }
});

Vue.component('default-layout', Default);
Vue.component('login-layout', Login);
Vue.component('error-layout', Error);
Vue.component('main-layout', Main);

Vue.component('hero-bar', HeroBar);
Vue.component('card-component', CardComponent);
Vue.component('custom-table', CustomTable);
Vue.component('back-save-buttons', SaveButton);
Vue.component('edit-button', EditButton);



Vue.mixin(filterMixins);
Vue.mixin(helpers);

fetch(process.env.BASE_URL + 'config.json').then((res) => {
    res.json().then((config) => {
        Vue.prototype.$config = config;
        config.lang = window.localStorage.getItem("lang");

        if (!config.lang) {
            window.localStorage.setItem("lang", "tr-TR");

            config.lang = "tr-TR";
        }

        fetch(process.env.BASE_URL + "lang/" + config.lang + ".json").then((res) => {
            res.json().then((lang) => {
                Vue.prototype.$lang = lang;

                new Vue({
                    store,
                    router,
                    render: (h) => h(App),
                }).$mount("#app");
            });
        });

        setInterval(() => {
            if (store.state.token != '') {
                api.auth.validate();
            }
        }, config.validateTime);
    });
});

Vue.directive('cleave', {
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
});
