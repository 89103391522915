<template>
	<router-link v-if="isShown" :to="goToId" class="button is-small is-link">
		<b-icon icon="pencil" size="is-small" />
	</router-link>
</template>

<script>
export default {
	props: {
		goToId: {
			type: Object,
			required: true
		},
		isShown: {
			type: Boolean,
			required: true
		}
	}
};
</script>
