<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.ResultsInfos.Title}}

            <p class="control" slot="right" style="margin-right: 16px">
                <saveButton />
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 1.5rem; padding-top: 1.4rem; padding-bottom: 1.5rem">
            <div class="columns">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-1" style="background-color: #FFFFFF; width: 10%; height: 100%; min-height: 100vh; display: flex; flex-direction: column;">
                        <verticalStep-bar />
                    </div>
                    <div class="column is-10">
                        <div class="columns" v-if="results_630" style="padding-left: 5rem">
                            <div class="column is-8">
                                <div class="columns is-mobile">
                                    <div class="column is-6">
                                        <b-field :label="this.$lang.ResultsInfos.Ta"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.Tmax" style="padding-top: 0.5rem"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.P890"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.Title_2"></b-field>
                                    </div>
                                    <div class="column">
                                        <b-field> <span><i>T</i><sub>a</sub></span> </b-field>
                                        <b-field> <span><i>T</i><sub>int,max</sub></span> </b-field>
                                        <b-field> <span><i>P</i><sub>890</sub></span> </b-field>
                                        <b-field> <i>P</i> </b-field>
                                    </div>
                                    <div class="column">
                                        <b-field> °C </b-field>
                                        <b-field style="padding-top: 0.5rem"> °C </b-field>
                                        <b-field> W </b-field>
                                        <b-field> W </b-field>
                                    </div>
                                    <div class="column">
                                        <b-field> : {{ envTemperature }} </b-field>
                                        <b-field style="padding-top: 0.5rem"> : {{ maxTemperature }} </b-field>
                                        <b-field> : {{ this.truncate(P_890, 1) }} </b-field>
                                        <b-field> : {{ this.truncate(P_Total, 1) }} </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field is-grouped">
                                    <div class="box" v-if="this.$store.state.isResultValid" style="width: 375px; height: 85px; background-color: #228B22;">
                                        <b-field class="box" style="padding: 15px">
                                            <b-field class="field is-grouped" :label="this.$lang.ResultsInfos.Title_3">
                                                <b-field style="margin-left: 4px"> {{ result_630A[0] }} </b-field>
                                            </b-field>
                                        </b-field>
                                    </div>
                                    <div class="box" v-if="!this.$store.state.isResultValid" style="width: 375px; background-color: #FF0000;">
                                        <b-field class="box" style="height: 50px; padding: 15px; ">
                                            <b-field class="field is-grouped" :label="this.$lang.ResultsInfos.Title_3">
                                                <b-field style="margin-left: 4px"> {{ result_630A[0] }} </b-field>
                                            </b-field>
                                        </b-field>
                                        <b-field class="box" style="width: 100px; height: 50px; padding: 15px; ">
                                            <b-field style="margin-left: 4px"> <b> {{ result_630A[1] }} </b> </b-field>
                                        </b-field>
                                        <b-field class="inline">
                                            <div class="flex__container">
                                                <b-field class="box" style="width: 250px; height: 50px; padding: 15px; display: block; margin-right: auto; margin-left: auto;">
                                                    <p class="content"><b> {{ result_630A[2] }} </b> </p>
                                                </b-field>
                                                <b-field class="box" style="width: 250px; height: 50px; padding: 15px; display: block; margin-right: auto; margin-left: auto;">
                                                    <p class="content"><b> {{ result_630A[3] }} </b> </p>
                                                </b-field>
                                                <b-field class="box" style="width: 250px; height: 50px; padding: 15px; display: block; margin-right: auto; margin-left: auto;">
                                                    <p class="content"><b> {{ result_630A[4] }} </b> </p>
                                                </b-field>
                                            </div>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="results_630"  style="padding-top: 5rem">
                            <div class="center2">
                                <div class="columns is-mobile is-multiline is-centered">
                                    <div class="field is-grouped">
                                        <b-field>
                                            <b> {{ this.$lang.NewProject.Title }} </b>
                                        </b-field>
                                    </div>
                                </div>

                                <div class="columns is-mobile is-multiline is-centered">
                                    <div class="field is-grouped">
                                        <b-field>
                                            {{ this.$lang.NewProject.Info_1 }} <br /> {{ this.$lang.NewProject.Info_2 }} <br />
                                        </b-field>
                                    </div>
                                </div>

                                <b-field class="block">
                                    <b-checkbox :value="true" disabled>
                                        {{this.$lang.NewProject.Accept}}
                                    </b-checkbox>
                                </b-field>
                            </div>
                        </div>

                        <p class="title is-4" v-if="!results_630" style="padding-left: 5rem">{{this.$lang.ResultsInfos.Title_4}}</p>
                        <div class="columns" v-if="!results_630" style="padding-left: 5rem">
                            <div class="column is-8">
                                <div class="columns">
                                    <div class="column is-4">
                                        <b-field :label="this.$lang.ResultsInfos.Sair"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.PanelConstant"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.HorizontalFactor"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.LostPower"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.SupValue"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.TempFactor"></b-field>
                                        <b-field style="padding-top: 0.5rem" :label="this.$lang.ResultsInfos.TempIncrease"></b-field>
                                        <b-field v-if="canValue_g_calculate" :label="this.$lang.ResultsInfos.TempInc_3_4"></b-field>
                                        <b-field :label="this.$lang.ResultsInfos.TempInc_UpPanel"></b-field>
                                    </div>
                                    <div class="column">
                                        <b-field v-html="$lang.ResultsInfos.Sair_2"></b-field>
                                        <b-field> <i>k</i> </b-field>
                                        <b-field> <i>d</i> </b-field>
                                        <b-field> <i>P</i> </b-field>
                                        <b-field> <i>x</i> </b-field>
                                        <b-field> <i>c</i> </b-field>
                                        <b-field v-html="$lang.ResultsInfos.TempIncrease_result"></b-field>
                                        <b-field v-html="$lang.ResultsInfos.TempInc_3_4_result" v-if="canValue_g_calculate"></b-field>
                                        <b-field v-html="$lang.ResultsInfos.TempInc_UpPanel_result"></b-field>
                                    </div>
                                    <div class="column">
                                        <b-field v-html="$lang.ResultsInfos.cm2"></b-field>
                                        <b-field> - </b-field>
                                        <b-field> - </b-field>
                                        <b-field> W </b-field>
                                        <b-field> - </b-field>
                                        <b-field> - </b-field>
                                        <b-field style="padding-top: 0.5rem"> K </b-field>
                                        <b-field v-if="canValue_g_calculate"> K </b-field>
                                        <b-field> K </b-field>
                                    </div>
                                    <div class="column">
                                        <b-field> : {{ this.$store.state.Sair }} </b-field>
                                        <b-field> : {{ this.truncate(value_k, 3) }} </b-field>
                                        <b-field> : {{ this.truncate(value_d, 2) }} </b-field>
                                        <b-field> : {{ this.truncate(P_Total, 1) }} </b-field>
                                        <b-field> : {{ this.truncate(value_x, 3) }} </b-field>
                                        <b-field> : {{ this.truncate(value_c, 3) }} </b-field>
                                        <b-field style="padding-top: 0.5rem"> : {{ this.truncate(deltaT_0_5, 1) }} </b-field>
                                        <b-field v-if="canValue_g_calculate"> : {{ this.truncate(deltaT_0_75, 1) }} </b-field>
                                        <b-field> : {{ this.truncate(deltaT_1, 1) }} </b-field>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field is-grouped">
                                    <div class="box" v-if="this.$store.state.isResultValid" style="width: 375px; height: 85px; background-color: #228B22;">
                                        <b-field class="box" style="padding: 15px">
                                            <b-field class="field is-grouped" :label="this.$lang.ResultsInfos.Title_3">
                                                <b-field style="margin-left: 4px"> {{ result_1600A[0] }} </b-field>
                                            </b-field>
                                        </b-field>
                                    </div>
                                    <div class="box" v-if="!this.$store.state.isResultValid" style="width: 375px; background-color: #FF0000;">
                                        <b-field class="box" style="height: 50px; padding: 15px; ">
                                            <b-field class="field is-grouped" :label="this.$lang.ResultsInfos.Title_3">
                                                <b-field style="margin-left: 4px"> {{ result_1600A[0] }} </b-field>
                                            </b-field>
                                        </b-field>
                                        <b-field class="box" style="width: 100px; height: 50px; padding: 15px; ">
                                            <b-field style="margin-left: 4px"> <b> {{ result_1600A[1] }} </b> </b-field>
                                        </b-field>
                                        <b-field class="inline">
                                            <div class="flex__container">
                                                <b-field class="box" style="width: 250px; height: 50px; padding: 15px; display: block; margin-right: auto; margin-left: auto;">
                                                    <p class="content"><b> {{ result_1600A[2] }} </b> </p>
                                                </b-field>
                                                <b-field class="box" style="width: 250px; height: 50px; padding: 15px; display: block; margin-right: auto; margin-left: auto;">
                                                    <p class="content"><b> {{ result_1600A[3] }} </b> </p>
                                                </b-field>
                                                <b-field class="box" style="width: 250px; height: 50px; padding: 15px; display: block; margin-right: auto; margin-left: auto;">
                                                    <p class="content"><b> {{ result_1600A[4] }} </b> </p>
                                                </b-field>
                                            </div>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="padding-top: 2rem; padding-left: 5rem" v-if="!results_630">
                            <div class="columns">
                                <div class="column is-4">
                                    <b-field v-if="canValue_f_calculate">
                                        <div v-if="this.$lang.Format.Lang == 'tr-TR'">
                                            <b-image src="/img/Pano_1 TR.png"
                                                     alt="Ae greater than 1.25"
                                                     style="width: 100%;">
                                            </b-image>
                                        </div>
                                        <div v-if="this.$lang.Format.Lang != 'tr-TR'">
                                            <b-image src="/img/Pano_1 EN.png"
                                                     alt="Ae greater than 1.25"
                                                     style="width: 100%;">
                                            </b-image>
                                        </div>
                                    </b-field>
                                    <b-field v-if="canValue_g_calculate">
                                        <div v-if="this.$lang.Format.Lang == 'tr-TR'">
                                            <b-image src="/img/Pano_2 TR.png"
                                                     alt="Ae smaller than 1.25"
                                                     style="width: 100%;">
                                            </b-image>
                                        </div>
                                        <div v-if="this.$lang.Format.Lang != 'tr-TR'">
                                            <b-image src="/img/Pano_2 EN.png"
                                                     alt="Ae smaller than 1.25"
                                                     style="width: 100%;">
                                            </b-image>
                                        </div>
                                    </b-field>
                                </div>
                                <div class="column is-6">
                                    <div class="center2">
                                        <div class="columns is-mobile is-multiline is-centered">
                                            <div class="field is-grouped">
                                                <b-field style="padding-left: 25rem">
                                                    <b> {{ this.$lang.NewProject.Title }} </b>
                                                </b-field>
                                            </div>
                                        </div>

                                        <div class="columns is-mobile is-multiline is-centered">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    {{ this.$lang.NewProject.Info_1 }} <br /> {{ this.$lang.NewProject.Info_2 }} <br />
                                                </b-field>
                                            </div>
                                        </div>

                                        <b-field class="block" style="padding-left: 20rem; width: 150%">
                                            <b-checkbox :value="true" disabled>
                                                {{this.$lang.NewProject.Accept}}
                                            </b-checkbox>
                                        </b-field>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>       

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import SaveButton from '@/components/SaveButton.vue';

    export default {
		name: 'PanelInfos',
        components: {
            VerticalStepBar,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                results_630: false,            

                panelTypeId: 0,
                virtualSurfaceIndex: 0,
                y: 0,
                g: 0,
                d: 0,
                envTemperature: 0,
                maxTemperature: 0,
                P_890: 0,
                P_Total: 0,
                result_630A: [],

                ip_Id: 0,
                Ae_value: 0,
                Sair_value: 0,
                value_k: 0,
                n_value: 0,
                value_d: 0,
                value_x: 0,
                deltaT_0_5: 0,
                Ab_val: 0,
                height_val: 0,
                canValue_f_calculate: false,
                value_f: 0,
                canValue_g_calculate: false,
                value_g: 0,
                value_c: 0,
                deltaT_0_75: 0,
                deltaT_1: 0,
                result_1600A: []
            }
        },
        mounted() {
            this.setResultTypeId();

            if (this.$store.state.totalLostPower_1 != null && this.$store.state.totalLostPower_1 != 0) {
                 this.P_Total = this.$store.state.totalLostPower_1;
            } else if (this.$store.state.totalLostPower_2 != null && this.$store.state.totalLostPower_2 != 0) {
                 this.P_Total = this.$store.state.totalLostPower_2;
            } else if (this.$store.state.totalLostPower_3 != null && this.$store.state.totalLostPower_3 != 0) {
                 this.P_Total = this.$store.state.totalLostPower_3;
            }

            var panelDimension = this.$store.state.panelDimension;
            var array = panelDimension.split("x");
            this.y = array[0];
            this.g = array[1];
            this.d = array[2];
            this.panelTypeId = this.$store.state.panelTypeIndex;
            this.virtualSurfaceIndex = this.$store.state.virtualSurfaceIndex;
            this.envTemperature = this.$store.state.envTemperature;
            this.maxTemperature = this.$store.state.maxTemperature;

                this.getResult_630A();
            

            this.Ab_val = this.g * this.d;
            this.ip_Id = this.$store.state.selectedIP_Id;
            this.Ae_value = this.$store.state.ae_value;
            this.Sair_value = this.$store.state.Sair;
            this.n_value = this.$store.state.seperationNumber;
            if (this.$store.state.ratedCurrentIndex != 1) {
                this.calculate_DeltaT();
            }

            this.$store.state.activeStep = 4;
            this.$store.state.isFooterFixed = false;
        },
        watch: {
            $route() {              
                this.envTemperature = this.$store.state.envTemperature;
                this.maxTemperature = this.$store.state.maxTemperature;
            }
        },
        methods: {    
            previous() {
                this.$store.isPageOpenedFromStepBar = false;
                this.$store.state.calcIndexFromOutputCircs = -1;

                if (this.$store.state.calcIndex == 3) {
                    router.push('/calc_Summary');
                } else {
                    router.push('/calculation');
                }
            },
            submit() {
                var payload = { P_890: this.P_890, P_Total: this.P_Total, result_630A: this.result_630A };
                this.$store.commit('setResultInfos_630A', payload);

                var payload2 = {
                    value_k: this.value_k, n_value: this.n_value, value_d: this.value_d, value_x: this.value_x, deltaT_0_5: this.deltaT_0_5, Ab_val: this.Ab_val, height_val: this.height_val, value_f: this.value_f, value_g: this.value_g, value_c: this.value_c, deltaT_0_75: this.deltaT_0_75, deltaT_1: this.deltaT_1, result_1600A: this.result_1600A
                };
                this.$store.commit('setResultInfos_1600A', payload2);

                this.$store.isPageOpenedFromStepBar = false;
                this.$store.canReportPageOpen = true;

                if (this.$store.state.memberId == 'TestMember') {
                    router.push('/LoginMember');
                } else {
                    router.push('/ReportPdfPage');
                }
            },
            setResultTypeId() {
                if (this.$store.state.ratedCurrentIndex == 1) {
                    this.results_630 = true;
                } else {
                    this.results_630 = false;
                }
            },

            getResult_630A() {
                var ip = 0;
                if (this.$store.state.panelIP == '') {
                    ip = 55;
                } else {
                    if (this.$store.state.panelIP == '>IP31') {
                        ip = 55;
                    } else {
                        ip = 31;
                    }
                }

                api.panels.get_P_890(this.virtualSurfaceIndex, this.y, this.g, this.d, this.maxTemperature, this.envTemperature, ip).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.P_890 = res.data;
                    }
                });   

                if (this.$store.state.ratedCurrentIndex == 1) {
                    var Pdifference = this.P_890 - this.P_Total;
                    if (Pdifference >= 0) {
                        if (this.$store.state.langCode == "tr-TR") {
                            this.result_630A.push("Panonuz sıcaklık artışı yönünden doğrulanmıştır.");
                        } else {
                            this.result_630A.push("Your panel is validated.");
                        }
                        this.$store.state.isResultValid = true;
                        this.$store.canFanCalcOpen = false;
                    } else {
                        if (this.$store.state.langCode == "tr-TR") {
                            this.result_630A.push("Panonuz sıcaklık artışı yönünden doğrulanmamıştır");
                            this.result_630A.push("Öneriler");
                            this.result_630A.push("Pano ölçülerini değiştirin.");
                            this.result_630A.push("Pano içi kurulu gücü azaltın.");
                            this.result_630A.push("Fan kullanın.");
                        } else {
                            this.result_630A.push("Your panel is not validated.");
                            this.result_630A.push("Recommendations:");
                            this.result_630A.push("Change panel dimensions.");
                            this.result_630A.push("Reduce power inside panel.");
                            this.result_630A.push("Use fan.");
                        }
                        this.$store.state.isResultValid = false;
                        this.$store.canFanCalcOpen = true;
                    }
                }
            },
            calculate_DeltaT() {    
                var isIP31 = false;
                if (this.ip_Id == 2) {
                    isIP31 = true;
                }
                var sairval = this.Sair_value;
                if (sairval == 'N/A' || sairval == null) {
                    sairval = 0;
                }

                api.panels.get_k_Value(this.Ae_value, isIP31, sairval).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.value_k = res.data;

                        api.panels.get_d_Value(this.Ae_value, isIP31, this.n_value).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.value_d = res.data;

                                api.panels.get_x_Value(this.Ae_value, isIP31).then((res) => {
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        this.value_x = res.data;

                                        api.panels.get_DeltaT_0_5(this.value_k, this.value_d, this.P_Total, this.value_x).then((res) => {
                                            if (res.isError) {
                                                console.log("error ");
                                            } else {
                                                this.deltaT_0_5 = res.data;

                                                this.get_DeltaT_1(isIP31);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            },

            get_DeltaT_1(isIP31) {
                if (this.Ae_value > 1.25) {
                    this.canValue_f_calculate = true;
                    this.canValue_g_calculate = false;
                    this.get_DeltaT_AeGreater(isIP31);
                } else {
                    this.canValue_f_calculate = false;
                    this.canValue_g_calculate = true;
                    this.get_DeltaT_AeSmaller(isIP31);
                }                              
            },
            get_DeltaT_AeGreater(isIP31) {
                api.panels.get_f_Value(this.y, this.g, this.d).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.value_f = res.data;

                        var sairval = this.Sair_value;
                        if (sairval == 'N/A' || sairval == null) {
                            sairval = 0;
                        }

                        api.panels.get_c_Value(this.Ae_value, isIP31, this.panelTypeId, this.virtualSurfaceIndex, this.value_f, this.value_g, sairval).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.value_c = res.data;
                                this.deltaT_1 = this.deltaT_0_5 * this.value_c;

                                this.getResult_1600A();
                            }
                        });
                    }
                });
            },
            get_DeltaT_AeSmaller(isIP31) {
                api.panels.get_g_Value(this.y, this.g).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.value_g = res.data;

                        var sairval = this.Sair_value;
                        if (sairval == 'N/A' || sairval == null) {
                            sairval = 0;
                        }

                        api.panels.get_c_Value(this.Ae_value, isIP31, this.panelTypeId, this.virtualSurfaceIndex, this.value_f, this.value_g, sairval).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.value_c = res.data;
                                this.deltaT_1 = this.deltaT_0_5 * this.value_c;
                                this.deltaT_0_75 = this.deltaT_1;

                                this.getResult_1600A();
                            }
                        });
                    }
                });
            },

            getResult_1600A() {
                var _temp1 = parseFloat(this.envTemperature);
                var _temp2 = parseFloat(this.deltaT_1);
                var _temp3 = parseFloat(this.maxTemperature);
                var Tdifference = _temp3 - (_temp1 + _temp2);
                if (Tdifference >= 0) {
                    if (this.$store.state.langCode == "tr-TR") {
                        this.result_1600A.push("Panonuz sıcaklık artışı yönünden doğrulanmıştır.");
                    } else {
                        this.result_1600A.push("Your panel is validated.");
                    }
                    this.$store.state.isResultValid = true;
                    this.$store.canFanCalcOpen = false;
                } else {  
                    if (this.$store.state.langCode == "tr-TR") {
                        this.result_1600A.push("Panonuz sıcaklık artışı yönünden doğrulanmamıştır");
                        this.result_1600A.push("Öneriler");
                        this.result_1600A.push("Pano ölçülerini değiştirin.");
                        this.result_1600A.push("Pano içi kurulu gücü azaltın.");
                        this.result_1600A.push("Fan kullanın.");
                    } else {
                        this.result_1600A.push("Your panel is not validated.");
                        this.result_1600A.push("Recommendations:");
                        this.result_1600A.push("Change panel dimensions.");
                        this.result_1600A.push("Reduce power inside panel.");
                        this.result_1600A.push("Use fan.");
                    }      
                    this.$store.state.isResultValid = false;
                    this.$store.canFanCalcOpen = true;
                }
            },

            truncate(numToBeTruncated, numOfDecimals) {              
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
        },

    };
</script>

<style>
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }

    .center2 {
        margin: auto;
        width: 50%;
        text-align: center;
    }
</style>
