<template>
    <div>
        <hero-bar :has-right-visible="false" menu-icon="desktop-mac">  </hero-bar>

        <div id="app" class="section is-main-section">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-3">
                    <b-field horizontal :label="this.$lang.Member.Password_Email_2" style="width: 125%;" :type="{ 'is-danger': hasError_email }" :message="this.emptyWarning_email">
                        <b-input v-model="email" type="email" maxlength="30" style="width: 65%;"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered">
                <b-button class="is-centered" type="is-text" @click="sendEmail()" style="font-size: 14px; color:blue">
                    {{ this.$lang.Member.Password_Email }}
                </b-button>
            </div>
        </div>

    </div>
</template>

<script type="text/javascript" src="path/to/zxcvbn.js"></script>
<script>
    // import router from '@/router';
    import api from '@/api';

    export default {
        name: 'PasswordEmail',
        data() {
            return {
                isActive: true,
                email: '',
                hasError_email: false,
                emptyWarning_email: ''
            }
        },
        mounted() {
            this.$store.state.isFooterFixed = true;
        },
        methods: {
            sendEmail() {
                api.members.sendResetPasswordEmail().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        var payload = {
                            resetEmail: this.email,
                        };
                        this.$store.commit('passwordReset', payload);

                        this.$buefy.toast.open(this.$lang.Member.Password_EmailSend);
                    }
                });
            },
        }
    };
</script>
