<template>
	<section id="particles-js" class="section hero is-fullheight is-error-section">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-centered">
					<div class="column is-two-fifths">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import 'particles.js/particles';
	const particlesJS = window.particlesJS;

	export default {
		name: 'Login',
		mounted() {
			document.documentElement.className = '';

			particlesJS.load('particles-js', 'assets/particles.json', null);
		},
		beforeDestroy() {
			document.documentElement.className = 'has-aside-left has-aside-mobile-transition has-navbar-fixed-top';
		},
		created() {
			
		}
	}
</script>
<style lang="scss" scoped>
	#particles-js {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #00d1b2;
		-webkit-box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);
		-moz-box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);
		box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);
	}
</style>