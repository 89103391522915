<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.Menus.Logs}}

            <p v-if="this.backBtnActive == true" class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" class="section is-main-section">
            <div v-if="this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1">
                <b-field :label="this.$lang.AdminExcel.Title_1"></b-field>
                <div class="columns" style="padding-top: 1rem; padding-left: 1.5rem">
                    <div class="columns is-multiline is-mobile">
                        <div class="columns">
                            <div class="column is-6">
                                <b-field grouped>
                                    <b-field :label="this.$lang.AdminExcel.FirstDate" style="padding-top:0.5rem"></b-field>
                                    <b-datetimepicker v-model="firstDate"
                                                      rounded
                                                      placeholder="Click to select..."
                                                      icon="calendar-today"
                                                      :icon-right="firstDate ? 'close-circle' : ''"
                                                      icon-right-clickable
                                                      @icon-right-click="clearDateTime_1"
                                                      :locale="locale"
                                                      :first-day-of-week="firstDayOfWeek"
                                                      :datepicker="{ showWeekNumber }">
                                    </b-datetimepicker>
                                </b-field>

                            </div>
                            <div class="column is-6">
                                <b-field grouped style="padding-left:3rem">
                                    <b-field :label="this.$lang.AdminExcel.SecondDate" style="padding-top:0.5rem"></b-field>
                                    <b-datetimepicker v-model="secondDate"
                                                      rounded
                                                      placeholder="Click to select..."
                                                      icon="calendar-today"
                                                      :icon-right="secondDate ? 'close-circle' : ''"
                                                      icon-right-clickable
                                                      @icon-right-click="clearDateTime_2"
                                                      :locale="locale"
                                                      :first-day-of-week="firstDayOfWeek"
                                                      :datepicker="{ showWeekNumber }">
                                    </b-datetimepicker>
                                </b-field>
                            </div>
                            <div class="column is-6">
                                <b-button @click="getLogsTable2">
                                    {{ this.$lang.AdminExcel.ListProjects }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="padding-top: 0.5rem"></div>
                <b-notification :closable="false">
                    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
                </b-notification>
                <div style="padding-top: 3rem"></div>
            </div>

            <b-table :data="logsdata"
                     :paginated="isPaginated"
                     :per-page="perPage">
                <b-table-column field="FullName" :label="this.$lang.AdminExcel.UserName" :td-attrs="columnTdAttrs" v-slot="props" width="250" :searchable="true">
                    {{ props.row.FullName }}
                </b-table-column>
                <b-table-column field="Title" :label="this.$lang.Member.Title" :td-attrs="columnTdAttrs" v-slot="props" width="250" :searchable="true">
                    {{ props.row.Title }}
                </b-table-column>
                <b-table-column field="ProjectName" :label="this.$lang.AdminExcel.ProjectName" :td-attrs="columnTdAttrs" v-slot="props" width="250" :searchable="true">
                    {{ props.row.ProjectName }}
                </b-table-column>
                <b-table-column field="PanelName" :label="this.$lang.AdminExcel.PanelName" :td-attrs="columnTdAttrs" v-slot="props" width="250" :searchable="true">
                    {{ props.row.PanelName }}
                </b-table-column>
                <b-table-column field="PanelNo" :label="this.$lang.AdminExcel.PanelNo" :td-attrs="columnTdAttrs" v-slot="props" width="150" :searchable="true">
                    {{ props.row.PanelNo }}
                </b-table-column>
                <b-table-column field="IP" :label="this.$lang.AdminExcel.IP" :td-attrs="columnTdAttrs" v-slot="props" width="150" :searchable="true">
                    {{ props.row.IP }}
                </b-table-column>
                <b-table-column field="AcceptDate" :label="this.$lang.AdminExcel.Date" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                    {{ props.row.AcceptDate }}
                </b-table-column>
            </b-table>

        </div>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'AcceptLogs',
        data() {
            return {
                isActive: true,
                isLoading: false,
                firstDate: new Date(),
                secondDate: new Date(),
                showWeekNumber: false,
                enableSeconds: false,
                hourFormat: undefined, // Browser locale
                locale: undefined, // Browser locale
                firstDayOfWeek: undefined, // 0 - Sunday
                isPaginated: true,
                perPage: 10,
                logsdata: [],
                backBtnActive: false
            }
        },
        mounted() {
            if (this.$store.state.previousRoute == 'projectInfos' || this.$store.state.previousRoute == 'panelInfos' || this.$store.state.previousRoute == 'envConditions' ||
                this.$store.state.previousRoute == 'calc_Busbar' || this.$store.state.previousRoute == 'calc_InputCircuit' || this.$store.state.previousRoute == 'calc_OutputCircuit' ||
                this.$store.state.previousRoute == 'calc_Manuel' || this.$store.state.previousRoute == 'calc_Summary' || this.$store.state.previousRoute == 'results' ||
                this.$store.state.previousRoute == 'reportPdfPage' || this.$store.state.previousRoute == 'calculation') {
                this.backBtnActive = true;
            } else {
                this.backBtnActive = false;
            }
            this.getLogsTable();

            this.$store.state.isFooterFixed = true;
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            getLogsTable() {
                this.isLoading = true;
                this.logsdata = [];               
                api.panels.getAcceptLogs().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.logsdata.push(e);
                        });
                        this.isLoading = false;
                    }
                });
            },
            getLogsTable2() {
                this.isLoading = true;
                this.logsdata = [];
                var first = this.formatDate(this.firstDate).toString();
                var second = this.formatDate(this.secondDate).toString();
                api.panels.getAcceptLogs2(first, second).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.logsdata.push(e);
                        });
                        this.isLoading = false;
                    }
                });
            },

            clearDateTime_1() {
                this.firstDate = null
            },
            clearDateTime_2() {
                this.secondDate = null
            },
            formatDate(date) {
                const year = date.toLocaleString('default', { year: 'numeric' });
                const month = date.toLocaleString('default', {
                    month: '2-digit',
                });
                const day = date.toLocaleString('default', { day: '2-digit' });

                return [year, month, day].join('-');
            },

            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            previous() {
                router.push('/' + this.$store.state.previousRoute);
            }
        }
    };
</script>
