import monthArray from '@/mixins/monthArray';

const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

export default {
	methods: {
		randomPassword(len = 8, minUpper = 0, minLower = 0, minNumber = -1, minSpecial = -1) {
			var A2Z = String.fromCharCode(...Array(91).keys()).slice(65);
			var a2z = String.fromCharCode(...Array(123).keys()).slice(97);
			var zero2nine = String.fromCharCode(...Array(58).keys()).slice(48);
			var specials = '+-!%*@';
			var chars = A2Z + a2z + zero2nine + specials;

			if (minSpecial < 0) chars = zero2nine + A2Z + a2z;
			if (minNumber < 0) chars = chars.replace(zero2nine, '');
			var minRequired = minSpecial + minUpper + minLower + minNumber;
			var rs = [].concat(
				Array.from({ length: minSpecial ? minSpecial : 0 }, () => specials[Math.floor(Math.random() * specials.length)]),
				Array.from({ length: minUpper ? minUpper : 0 }, () => A2Z[Math.floor(Math.random() * A2Z.length)]),
				Array.from({ length: minLower ? minLower : 0 }, () => a2z[Math.floor(Math.random() * a2z.length)]),
				Array.from({ length: minNumber ? minNumber : 0 }, () => zero2nine[Math.floor(Math.random() * zero2nine.length)]),
				Array.from(
					{ length: Math.max(len, minRequired) - (minRequired ? minRequired : 0) },
					() => chars[Math.floor(Math.random() * chars.length)]
				)
			);
			/* eslint-disable */
			return rs.sort(() => Math.random() > Math.random()).join('');
			/* eslint-enable */
		},
		numberFormat(num, size) {
			return num
				.toFixed(size) // always two decimal digits
				.replace('.', ',') // replace decimal point character with ,
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		},
		currencyFormat(num, size) {
			return (
				num
					.toFixed(size) // always two decimal digits
					.replace('.', ',') // replace decimal point character with ,
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' TL'
			);
		},
		datetimeFormat(datetimeStr) {
			if (!datetimeStr) {
				return '';
			}

			var date = new Date(datetimeStr);
			var dateStr =
				('00' + date.getDate()).slice(-2) +
				'-' +
				('00' + (date.getMonth() + 1)).slice(-2) +
				'-' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return dateStr;
		},
		dateFormat(dateStr) {
			if (!dateStr) {
				return '';
			}

			var date = new Date(dateStr);
			var dateStrFormated = ('00' + date.getDate()).slice(-2) + '-' + ('00' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();

			return dateStrFormated;
		},
		getMonthList() {
			return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
		},
		getYearList() {
			var arr = [];

			for (var i = Number(this.$config.Year); i > Number(this.$config.Year) - 10; i--) {
				arr.push(String(i));
			}

			return arr;
		},
		getTerm(dateStr) {
			var date = new Date(dateStr);
			var dateStrFormated = monthArray[date.getMonth()] + ' ' + date.getFullYear();

			return dateStrFormated;
		},
		isNumeric(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},
		isIsoDateString(value) {
			return value && typeof value === 'string' && isoDateFormat.test(value);
		},
		isDate(value) {
			if (value instanceof Date && value.getTime()) {
				return true;
			}

			return false;
		},
		handleDates(body) {
			if (body === null || body === undefined || typeof body !== 'object') {
				return body;
			}

			for (const key of Object.keys(body)) {
				const value = body[key];
				if (this.isIsoDateString(value)) {
					body[key] = new Date(value);
				} else if (typeof value === 'object') {
					this.handleDates(value);
				}
			}
		},
		replaceDates(body) {
			if (body === null || body === undefined || typeof body !== 'object') {
				return body;
			}

			for (const key of Object.keys(body)) {
				const value = body[key];
				if (this.isDate(value)) {
					body[key] = value.toISOString();
				} else if (typeof value === 'object') {
					this.replaceDates(value);
				}
			}
		}
	}
};
