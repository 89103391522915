<template>
    <b-tabs position="is-centered" type="is-toggle is-danger is-light is-outlined" v-model="activeTab">
        <b-tab-item>
            <template slot="header">
                <b-button class="button is-light" @click="changeTab(0)" style="height: 80px; width: 240px">
                    <div class="columns">
                        <div class="column is-5">
                            <img src="/img/icon_1.jpg" style="width:75px; padding-top: 0.5rem" />
                        </div>
                        <div class="column is-2">
                            <div style="padding-top: 0.5rem">
                                {{this.$lang.Calculation.InnerTitle_1}}<br />{{this.$lang.Calculation.InnerTitle_2}}<br />{{this.$lang.Calculation.InnerTitle_3}}
                            </div>
                        </div>
                    </div>
                </b-button>
            </template>
        </b-tab-item>

        <b-tab-item disabled>
            <template slot="header">
                <b-button style="height: 1px; width: 25px; opacity: 0">

                </b-button>
            </template>
        </b-tab-item>

        <b-tab-item v-if="isSecondTabActive">
            <template slot="header">
                <button class="button is-light" @click="changeTab(1)" style="height: 80px; width: 240px;">
                    <div class="columns">
                        <div class="column is-5">
                            <img src="/img/icon_2.jpg" style="width:75px; padding-top: 0.5rem" />
                        </div>
                        <div class="column is-2">
                            <div style="padding-top: 0.5rem">
                                {{this.$lang.Calculation.InnerTitle_4}}<br />{{this.$lang.Calculation.InnerTitle_5}}<br />{{this.$lang.Calculation.InnerTitle_8}}
                            </div>
                        </div>
                    </div>
                </button>
            </template>
        </b-tab-item>

        <b-tab-item v-if="isSecondTabActive" disabled>
            <template slot="header">
                <b-button style="height: 1px; width: 25px; opacity: 0">

                </b-button>
            </template>
        </b-tab-item>

        <b-tab-item> 
            <template slot="header">
                <button class="button is-light" style="height: 80px; width: 240px">
                    <div class="columns">
                        <div class="column is-5">
                            <img src="/img/icon_3.jpg" style="width:75px; padding-top: 0.5rem" />
                        </div>
                        <div class="column is-2">
                            <div style="padding-top: 0.5rem">
                                {{this.$lang.Calculation.InnerTitle_6}}<br /> {{this.$lang.Calculation.InnerTitle_7}}<br />{{this.$lang.Calculation.InnerTitle_8}}
                            </div>
                        </div>
                    </div>
                </button>
            </template>
        </b-tab-item>
    </b-tabs>
</template>

<script>
    import router from '@/router';

    export default {
        name: 'CalculationTabs',
        data() {
            return {
                activeTab: 0,
                isSecondTabActive: false
            }
        },
        beforeMount() {
            if (this.$store.state.ratedCurrentIndex == 1) {
                this.isSecondTabActive = true;
            } else {
                this.isSecondTabActive = false;
            }
        },
        mounted() {
            if (this.$store.state.ratedCurrentIndex == 1) {
                // this.activeTab = this.$store.state.calcIndex - 1;
                this.activeTab = 2 * this.$store.state.calcIndex - 1;
            } else {
                if (this.$store.state.calcIndex == 3) {
                    this.activeTab = 2;
                } else {
                    this.activeTab = this.$store.state.calcIndex - 1;
                }
            }
        },
        methods: {           
            changeTab(index) {
                this.$store.state.calcIndex = index + 1;
                this.$store.state.calcIndexFromOutputCircs = index;
                this.$store.state.currentRouteFromOutputCircs = this.$route.name;
                router.push('/calculation');
            }           
        }
    };
</script>

<style>
    .b-tabs .tabs li.is-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0;
    }
</style>