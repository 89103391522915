<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{ this.$lang.ReportPage.Title_1 }}

            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
        </hero-bar>

        <div class="columns">
            <div class="column is-1" style="padding-left: 2rem">

            </div>
            <div class="column">
                <embed :src="pdfsrc" type="application/pdf" width="100%" height="900px" />
            </div>
        </div>

        <b-notification :closable="false">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </b-notification>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'ReportPdfPage2',
        data() {
            return {
                isActive: true,
                isLoading: false,
                pdfsrc: ''               
            }
        },
        mounted() {
            this.getReportPdf();

            this.$store.state.isFooterFixed = true;
        },
        methods: {              
            getReportPdf() {
                this.isLoading = true;
                api.panels.getReportPdf(this.$lang.Format.Lang, this.$store.state.memberId, this.$store.state.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.pdfsrc = process.env.VUE_APP_PDFURL + '/PdfReports' + '/Project-Report' + this.$store.state.projectId + '.pdf';
                        if (this.$store.state.memberId == 'TestMember') {
                            this.pdfsrc += '#toolbar=0';
                        } else {
                            this.pdfsrc = this.pdfsrc.replace("#toolbar=0", "");
                            this.isLoading = false;
                        }
                    }
                });
            },
            previous() {
                router.push('/myProjects');
            }
        },

    };
</script>