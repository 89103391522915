<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <!--<a class="navbar-item" href="https://bulma.io">
                    <img style="width: auto" src="/img/e-kabin-logo.jpg" />
                </a>-->
                <a href="/">
                    <div style="width: 190px; height: 100%; background-color: #ffffff;">
                        <span style="padding-left: 1.75rem; padding-top: 0.5rem">
                            <img style="width: 150px; height: 100%; margin: 0; padding: 0;" src="/img/E-kabin logo.jpg" /> &nbsp;
                        </span>
                    </div>
                </a>

                <div class="navbar-start">
                    <div>
                        {{this.$lang.Menus.ProjectTitle}}
                    </div>
                </div>

                <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navMenu">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>


            </div>

            <div id="navMenu" class="navbar-menu">


                <div class="navbar-end">
                    <!--<router-link to="/" class="navbar-item">{{ this.$lang.Menus.MainPage }}</router-link>-->
                    <div class="navbar-item" style="padding: 0rem;">
                        <b-button @click="openMainPage()" class="customNavbarBtn">{{ this.$lang.Menus.MainPage }}</b-button>
                    </div>
                    <div class="navbar-item" style="padding: 0rem">
                        <b-button @click="openNewProject()" class="customNavbarBtn">{{ this.$lang.Menus.NewProject }}</b-button>
                    </div>
                    <div class="navbar-item" style="padding: 0rem" v-if="this.$store.state.memberId != 'TestMember'">
                        <b-button @click="openMyProjects()" class="customNavbarBtn">{{ this.$lang.Menus.MyProjects }}</b-button>
                    </div>
                    <div class="navbar-item" style="padding: 0rem" v-if="this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1">
                        <b-button @click="openAllProjects()" class="customNavbarBtn">{{ this.$lang.Menus.AllProjects }}</b-button>
                    </div>
                    <div class="navbar-item" style="padding: 0rem" v-if="this.$store.state.memberId != 'TestMember'">
                        <b-button @click="openCircuitDevices()" class="customNavbarBtn">{{ this.$lang.Menus.CircDevices }}</b-button>
                    </div>
                    <div class="navbar-item" style="padding: 0rem" v-if="this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1">
                        <b-button @click="openAcceptLogs()" class="customNavbarBtn">{{ this.$lang.Menus.Logs }}</b-button>
                    </div>
                    <div class="navbar-item" style="padding: 0rem">
                        <b-field class="field is-grouped langsymbol">
                            <b-field class="material-symbols-outlined" style="padding-top: 0.3rem; padding-left: 0rem">
                                language
                            </b-field>
                            <b-field>
                                <b-select v-model="selectedLanguage" @input="setLanguage">
                                    <option v-for="option in langlist"
                                            :value="option"
                                            :key="option.id">
                                        {{ option }}
                                    </option>
                                </b-select>
                            </b-field>
                        </b-field>
                    </div>
                    <div class="navbar-item" style="padding: 0rem">
                        <!--<router-link v-if="this.$store.state.memberId == 'TestMember'" to="/AddMember" class="button is-warning">
                            <b-icon icon="account" type="is-primary" size="is-small"></b-icon>{{ this.$lang.Login.SignUpBtn }}
                        </router-link>-->
                        <b-button v-if="this.$store.state.memberId == 'TestMember'" class="button is-warning" @click="openSignUp()">
                            <b-icon icon="account" type="is-primary" size="is-small"></b-icon>{{ this.$lang.Login.SignUpBtn }}
                        </b-button>
                    </div>
                    <div class="navbar-item" style="padding-left: 0.5rem">
                        <!--<router-link :to="openSignIn()" class="button is-white">
                            <b-icon v-if="this.$store.state.memberId == 'TestMember'" icon="login" type="is-primary" size="is-small"></b-icon>
                            <b-icon v-if="this.$store.state.memberId != 'TestMember'" icon="account" type="is-primary" size="is-small"></b-icon>
                            {{ this.signInBtnText }}
                        </router-link>-->
                        <b-button class="button is-white" @click="openSignIn()">
                            <b-icon v-if="this.$store.state.memberId == 'TestMember'" icon="login" type="is-primary" size="is-small"></b-icon>
                            <b-icon v-if="this.$store.state.memberId != 'TestMember'" icon="account" type="is-primary" size="is-small"></b-icon>
                            {{ this.signInBtnText }}
                        </b-button>

                        <b-button class="customNavbarBtn" v-if="this.$store.state.memberId != 'TestMember'" title="Log out" @click="logout">
                            <b-icon icon="logout" style="padding-top: 1rem; padding-left: 1rem" />

                            <!--<span>{{ this.$lang.Login.LogoutBtn }}</span>-->
                        </b-button>
                    </div>
                    <!--<div class="navbar-item" style="padding-left: 0rem; padding-top: 0rem; padding-bottom: 0rem; padding-right: 0.5rem; ">
                        <b-button class="customNavbarBtn" v-if="this.$store.state.memberId != 'TestMember'" title="Log out" @click="logout">
                            <b-icon icon="logout" style="padding-top: 1rem; padding-left: 1rem" />-->
                    <!--<span>{{ this.$lang.Login.LogoutBtn }}</span>-->
                    <!--</b-button>
                    </div>-->
                </div>
            </div>
        </nav>
        <slot></slot>
        <footer-bar />
    </div>
</template>

<script>
    import FooterBar from '@/components/FooterBar.vue';
    import router from '@/router';
    // import api from '@/api';

    export default {
        name: 'Main',
        components: {
            FooterBar
        },
        data() {
            return {
                signInBtnText: this.$lang.Login.LoginBtn,
                selectedLanguage: '',
                langlist: ["TR", "EN"]
            }
        },
        mounted() {
            if (!this.$store.state.rememberMe && sessionStorage.getItem('isOpenedFirstTime') == null) {
                this.$store.state.userInfo = {};
                sessionStorage.setItem('isOpenedFirstTime', 'true');
            }

            if (this.$store.state.userInfo.Id != null) {
                this.$store.state.memberId = this.$store.state.userInfo.Id;
                this.$store.state.isAdmin = this.$store.state.userInfo.IsAdmin;
                this.signInBtnText = this.$store.state.userInfo.Name;
            } else {
                this.$store.state.memberId = 'TestMember';
                this.signInBtnText = this.$lang.Login.LoginBtn;
            }

            if (this.$config.lang == "tr-TR") {
                this.selectedLanguage = "TR"
            } else if (this.$config.lang == "en-EN") {
                this.selectedLanguage = "EN"
            }

            const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
            $navbarBurgers.forEach(el => {
                el.addEventListener('click', () => {
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);
                    el.classList.toggle('is-active');
                    $target.classList.toggle('is-active');
                });
            });
        },
        watch: {
            $route() {
                if (this.$store.state.userInfo.Id != null) {
                    this.$store.state.memberId = this.$store.state.userInfo.Id;
                    this.$store.state.isAdmin = this.$store.state.userInfo.IsAdmin;
                    this.signInBtnText = this.$store.state.userInfo.Name;
                } else {
                    this.$store.state.memberId = 'TestMember';
                    this.signInBtnText = this.$lang.Login.LoginBtn;
                }
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        methods: {
            openMainPage() {
                router.push('/');
            },
            openSignIn() {
                if (this.$store.state.memberId == 'TestMember') {
                    if (this.$store.state.projectId != '' && !this.$store.state.isPrevProject) {
                        this.$buefy.dialog.confirm({
                            title: this.$lang.LoginWarningDialog.Title,
                            message: this.$lang.LoginWarningDialog.Message,
                            confirmText: this.$lang.LoginWarningDialog.Confirm,
                            cancelText: this.$lang.LoginWarningDialog.Cancel,
                            type: 'is-danger',
                            hasIcon: true,
                            onConfirm: () => {
                                router.push('/LoginMember');
                            },
                            onCancel: () => {

                            }
                        })
                    } else {
                        router.push('/LoginMember');
                    }
                } else {
                    this.$store.state.previousRoute = this.$route.name;
                    router.push('/UpdateMember');
                }
            },
            openSignUp() {
                if (this.$store.state.memberId == 'TestMember') {
                    if (this.$store.state.projectId != '' && !this.$store.state.isPrevProject) {
                        this.$buefy.dialog.confirm({
                            title: this.$lang.LoginWarningDialog.Title,
                            message: this.$lang.LoginWarningDialog.Message,
                            confirmText: this.$lang.LoginWarningDialog.Confirm,
                            cancelText: this.$lang.LoginWarningDialog.Cancel,
                            type: 'is-danger',
                            hasIcon: true,
                            onConfirm: () => {
                                router.push('/AddMember');
                            },
                            onCancel: () => {

                            }
                        })
                    } else {
                        router.push('/AddMember');
                    }
                } else {
                    router.push('/AddMember');
                }
            },
            logout() {
                this.$store.commit('logout', this.$lang.Login.LoginBtn);
                this.$store.state.memberId = 'TestMember';
                this.$store.canReportPageOpen = false;
                this.signInBtnText = this.$lang.Login.LoginBtn;
                router.push('/');
            },
            setLanguage() {
                if (this.selectedLanguage == "TR") {
                    this.$config.lang = "tr-TR";
                } else if (this.selectedLanguage == "EN") {
                    this.$config.lang = "en-EN";
                }
                window.localStorage.setItem("lang", this.$config.lang);
                location.reload();
            },
            openCircuitDevices() {
                this.$store.state.previousRoute = this.$route.name;
                this.$store.state.isCircDevicesFromCalculation = false;
                if (this.$store.state.isAdmin == 1) {
                    router.push('/CircuitDevices_Admin');
                } else {
                    router.push('/CircuitDevices');
                }
            },
            openAllProjects() {
                this.$store.state.previousRoute = this.$route.name;
                router.push('/AllProjects');
            },
            openNewProject() {
                if (this.$store.state.projectId != '' && !this.$store.state.isPrevProject) {
                    this.$buefy.dialog.confirm({
                        title: this.$lang.DeleteDialog.Title,
                        message: this.$lang.DeleteDialog.Message_2,
                        confirmText: this.$lang.DeleteDialog.Confirm_2,
                        cancelText: this.$lang.DeleteDialog.Cancel,
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            router.push('/NewProject');
                        },
                        onCancel: () => {

                        }
                    })
                } else {
                    router.push('/NewProject');
                }
            },
            openMyProjects() {
                if (this.$store.state.projectId != '' && !this.$store.state.isPrevProject) {
                    this.$buefy.dialog.confirm({
                        title: this.$lang.DeleteDialog.Title,
                        message: this.$lang.DeleteDialog.Message_2,
                        confirmText: this.$lang.DeleteDialog.Confirm_2,
                        cancelText: this.$lang.DeleteDialog.Cancel,
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            router.push('/MyProjects');
                        },
                        onCancel: () => {

                        }
                    })
                } else {
                    router.push('/MyProjects');
                }
            },
            openAcceptLogs() {
                this.$store.state.previousRoute = this.$route.name;
                router.push('/AcceptLogs');
            }
        }
    };
</script>

<style>
    .customNavbarBtn {
        background-color: #878787;
        border-color: #878787;
        color: #ffffff;
        border-radius: 0px;
    }

        .customNavbarBtn:hover {
            background-color: #ffffff;
            border-color: #ffffff;
            color: #878787
        }

        .customNavbarBtn:focus {
            background-color: #ffffff;
            border-color: #ffffff;
            color: #878787
        }


    .langsymbol {
        margin-left: 2.5rem;
        margin-right: 0.5rem;
    }

    @media only screen and (max-width: 1024px) {
        .langsymbol {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }

    .navbar-start {
       padding-left: 2rem;
       color: white;
       font-size: 15px;
       display: flex;
       align-items: center;
    }

    .navbar-brand {
        align-items:center;
    }
</style>
