import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/NewLayout',
		name: 'layout',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/NewLayoutComponent.vue').default
	},
	{
		path: '/',
		name: 'mainMenu',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/MainMenu.vue').default
	},
	{
		path: '/AddMember',
		name: 'addMember',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/AddMember.vue').default
	},
	{
		path: '/UpdateMember',
		name: 'updateMember',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/UpdateMember.vue').default
	},
	{
		path: '/LoginMember',
		name: 'loginMember',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/LoginMember.vue').default
	},
	{
		path: '/ForgetPassword',
		name: 'forgetPassword',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/ForgetPassword.vue').default
	},
	{
		path: '/PasswordEmail',
		name: 'passwordEmail',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/PasswordEmail.vue').default
	},
	{
		path: '/MyProjects',
		name: 'myProjects',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/MyProjects.vue').default
	},
	{
		path: '/AllProjects',
		name: 'allProjects',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/AllProjects.vue').default
	},
	{
		path: '/AcceptLogs',
		name: 'acceptLogs',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/AcceptLogs.vue').default
	},
	{
		path: '/NewProject',
		name: 'newProject',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/NewProject.vue').default
	},
	{
		path: '/ProjectInfos',
		name: 'projectInfos',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/ProjectInfos.vue').default
	},
	{
		path: '/PanelInfos',
		name: 'panelInfos',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/PanelInfos.vue').default
	},
	{
		path: '/EnvConditions',
		name: 'envConditions',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/EnvConditions.vue').default
	},
	{
		path: '/Calculation',
		name: 'calculation',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Calculation.vue').default
	},
	{
		path: '/Calc_Busbar',
		name: 'calc_Busbar',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Calc_Busbar.vue').default
	},
	{
		path: '/Calc_InputCircuit',
		name: 'calc_InputCircuit',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Calc_InputCircuit.vue').default
	},
	{
		path: '/Calc_OutputCircuit',
		name: 'calc_OutputCircuit',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Calc_OutputCircuit.vue').default
	},
	{
		path: '/Calc_Manuel',
		name: 'calc_Manuel',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Calc_Manuel.vue').default
	},
	{
		path: '/Calc_Summary',
		name: 'calc_Summary',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Calc_Summary.vue').default
	},
	{
		path: '/Results',
		name: 'results',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Results.vue').default
	},
	{
		path: '/ReportPdfPage',
		name: 'reportPdfPage',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/ReportPdfPage.vue').default
	},
	{
		path: '/ReportPdfPage2',
		name: 'reportPdfPage2',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/ReportPdfPage2.vue').default
	},
	{
		path: '/Fan_Calc',
		name: 'fan_Calc',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/Fan_Calc.vue').default
	},
	{
		path: '/FanPdfPage',
		name: 'fanPdfPage',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/FanPdfPage.vue').default
	},
	{
		path: '/CircuitDevices',
		name: 'circuitDevices',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/CircuitDevices.vue').default
	},
	{
		path: '/CircuitDevices_Admin',
		name: 'circuitDevices_Admin',
		meta: {
			layout: 'main',
			title: 'PanelTemp Isınma Hesabı Yazılımı',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Giriş'
				}
			]
		},
		component: require('@/views/CircuitDevices_Admin.vue').default
	},
	{
		path: '*',
		name: 'pageNotFound',
		meta: {
			layout: 'error',
			title: 'Sayfa Bulunamadı - PanelTemp',
			requiresLogin: false,
			metaTags: [
				{
					name: 'description',
					content: 'Sayfa Bulunamadı'
				}
			]
		},
		component: require('@/views/404.vue').default
	}
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});

router.beforeEach(async (to, from, next) => {
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

	if (!nearestWithMeta) {
		return next();
	}

	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		.forEach((tag) => document.head.appendChild(tag));

	return next();
});

export default router;

export function useRouter() {
	return router;
}
