<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{ this.$lang.ReportPage.Title_1 }}

            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right" v-if="!this.$store.state.isResultValid">
                <b-button class="button is-primary" @click="openFanCalc" style="float: right;">{{this.$lang.Menus.FanCalcBtn}}</b-button>
            </p>
        </hero-bar>

        <div class="columns" style="padding-top: 0.5rem">
            <div class="column is-1" style="background-color: #FFFFFF; width: 10%; height: 100%; min-height: 100vh; display: flex; flex-direction: column;">
                <div style="padding-left: 1.5rem; padding-top: 1.4rem">
                    <verticalStep-bar />
                </div>
            </div>
            <div class="column" style="padding: 0rem">
                <embed :src="pdfsrc" type="application/pdf" width="100%" height="900px" />
            </div>
        </div>

        <b-modal v-model="isLoginModalActive" :width="400">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ this.$lang.Login.LoginTitle }}</p>
                </header>
                <section class="modal-card-body">
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="column is-8">
                            <b-field :type="{ 'is-danger': hasError_email }" :message="this.emptyWarning_email">
                                <b-input type="email"
                                         v-model="email"
                                         :placeholder="this.$lang.Login.Email"
                                         required>
                                </b-input>
                            </b-field>

                            <b-field :type="{ 'is-danger': hasError_password }" :message="this.emptyWarning_password">
                                <b-input type="password"
                                         v-model="password"
                                         password-reveal
                                         :placeholder="this.$lang.Login.Password"
                                         required>
                                </b-input>
                            </b-field>

                            <b-field :type="{ 'is-danger': hasError_signIn }" :message="this.warning_signIn"></b-field>

                            <!--<div class="level">
                                <p class="level-left"><b-checkbox v-model="rememberMe">{{ this.$lang.Login.RememberMe }}</b-checkbox></p>
                                <p class="level-right">
                                    <b-button type="is-text" @click="forgetPassword()">
                                        {{ this.$lang.Login.ForgetPassword }}
                                    </b-button>
                                </p>
                            </div>-->

                            <div class="level">
                                <p class="level-left">
                                    <b-button :label="this.$lang.Login.SignUpBtn"
                                              type="is-primary"
                                              @click="openSignUpPage()" />
                                </p>
                                <p class="level-right">
                                    <b-button :label="this.$lang.Login.LoginBtn"
                                              type="is-danger"
                                              @click="signIn()" />
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer class="modal-card-foot"></footer>
            </div>
        </b-modal>

        <b-notification :closable="false">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </b-notification>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    export default {
        name: 'ReportPdfPage',
        components: {
            VerticalStepBar
        },
        data() {
            return {
                isActive: true,
                isLoading: false,
                pdfsrc: '',
                isLoginModalActive: false,
                canSignIn: false,
                hasError_email: false,
                emptyWarning_email: '',
                hasError_password: false,
                emptyWarning_password: '',
                hasError_signIn: false,
                warning_signIn: '',
                email: '',
                password: ''
            }
        },
        mounted() {
            this.setCurrentDate();
            this.setProjectInfos();
            this.setPanelInfos();
            this.setEnvConditions();
            this.setCalculationDatas();

            this.saveProject();
            this.$store.state.activeStep = 5;
            this.$store.state.isFooterFixed = true;
        },
        methods: {
            openSignUpPage() {
                this.$store.state.currentRoute = this.$route.name;
                router.push('/addMember');
            },
            signIn() {
                if (this.email == '') {
                    this.hasError_email = true;
                    this.emptyWarning_email = this.$lang.Menus.EmptyWarning;
                } else if (this.password == '') {
                    this.hasError_password = true;
                    this.emptyWarning_password = this.$lang.Menus.EmptyWarning;
                } else {
                    this.canSignIn = true;

                    const loginModel = {
                        Email: this.email,
                        Passwordd: this.password
                    };
                    api.members.encryptLogin(loginModel).then((resCrypto) => {
                        if (resCrypto.isError) {
                            console.log("error ");
                        } else {
                            api.members.getMember(resCrypto.data[0], resCrypto.data[1] + '').then((res) => {
                                if (res == null) {
                                    this.hasError_signIn = true;
                                    this.warning_signIn = this.$lang.Menus.LoginWarning;
                                } else {
                                    this.hasError_signIn = false;
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        this.$store.commit('user', res.data);
                                        var memberId = res.data.Id;
                                        this.$store.state.isAdmin = res.data.IsAdmin;

                                        api.panels.updateProjectMemberId(this.$store.state.projectId, memberId).then((res) => {
                                            if (res.isError) {
                                                console.log("error ");
                                            } else {
                                                this.$store.state.memberId = memberId + '';
                                                this.updateAcceptLog_2();
                                                this.isLoginModalActive = false;
                                                this.getReportPdf(this.$store.state.projectId);
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }

                if (this.email != '') {
                    this.hasError_email = false;
                }
                if (this.password != '') {
                    this.hasError_password = false;
                }
            },
            getReportPdf(proId) {
                this.isLoading = true;
                api.panels.getReportPdf(this.$lang.Format.Lang, this.$store.state.memberId, proId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isLoading = false;
                        this.pdfsrc = process.env.VUE_APP_PDFURL + '/PdfReports' + '/Project-Report' + proId + '.pdf';
                        if (this.$store.state.memberId == 'TestMember') {
                            this.pdfsrc += '#toolbar=0';
                        } else {
                            this.pdfsrc = this.pdfsrc.replace("#toolbar=0", "");
                        }
                    }
                });
            },
            saveProject() {
                this.calcIndex = this.$store.state.calcIndex;
                var newSair = this.Sair;
                if (this.Sair == 'N/A') {
                    newSair = null;
                }

                if (!this.$store.state.isPrevProject) {
                    if (this.$store.state.projectId == this.$store.state.prevProjectId) {
                        const project = {
                            ProjectId: this.$store.state.projectId,
                            MemberId: this.$store.state.memberId,
                            IsCompleted: 1,
                            ProjectName: this.projectName,
                            PanelName: this.panelName,
                            PanelNo: this.panelNo,
                            PanelRatedCurrent: this.panelRatedCurrent,
                            RatedCurrentIndex: this.$store.state.ratedCurrentIndex,
                            TotalEyesNumber: this.totalEyesNumber,
                            EyeNo: this.eyesNumber,
                            PanelType: this.panelType,
                            PanelTypeId: this.$store.state.panelTypeIndex,
                            PanelDimension: this.panelDimension,
                            IP: this.panelIP,
                            Sair: newSair,
                            SurfaceInfo: this.$store.state.surfaceExplanation,
                            VirtualSurface: this.$store.state.virtualSurface,
                            VirtualSurfaceIndex: this.$store.state.virtualSurfaceIndex,
                            VirtualSurfaceOption: this.$store.state.virtualSurfaceOption,
                            SeperationNumber: this.seperationNumber,
                            Ae: this.$store.state.ae_value,
                            Ta: this.envTemperature,
                            Tmax: this.maxTemperature,
                            Altitude: this.altitude,
                            CalcTypeId: this.calcIndex,
                            KnownLostPower: this.knownTotalLostpower,
                            InPanelTemp: this.inPanelTemperature,
                            InEnvTemp: this.envMeasureTemperature,
                            InPanelLostPower: this.inPanelTotalLostpower,
                            CalcDeviceLostPower: this.$store.state.totalLostPower_3,
                            ReportDate: this.currentDate2
                        };
                        if (this.$store.state.memberId == 'TestMember') {
                            this.isLoginModalActive = true;
                        }
                        api.panels.updateProject(project).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.ReportPage.Updated);
                                this.getReportPdf(this.$store.state.projectId);
                            }
                        });
                    } else {
                        const project = {
                            MemberId: this.$store.state.memberId,
                            IsCompleted: 1,
                            ProjectName: this.projectName,
                            PanelName: this.panelName,
                            PanelNo: this.panelNo,
                            PanelRatedCurrent: this.panelRatedCurrent,
                            RatedCurrentIndex: this.$store.state.ratedCurrentIndex,
                            TotalEyesNumber: this.totalEyesNumber,
                            EyeNo: this.eyesNumber,
                            PanelType: this.panelType,
                            PanelTypeId: this.$store.state.panelTypeIndex,
                            PanelDimension: this.panelDimension,
                            IP: this.panelIP,
                            Sair: newSair,
                            SurfaceInfo: this.$store.state.surfaceExplanation,
                            VirtualSurface: this.$store.state.virtualSurface,
                            VirtualSurfaceIndex: this.$store.state.virtualSurfaceIndex,
                            VirtualSurfaceOption: this.$store.state.virtualSurfaceOption,
                            SeperationNumber: this.seperationNumber,
                            Ae: this.$store.state.ae_value,
                            Ta: this.envTemperature,
                            Tmax: this.maxTemperature,
                            Altitude: this.altitude,
                            CalcTypeId: this.calcIndex,
                            KnownLostPower: this.knownTotalLostpower,
                            InPanelTemp: this.inPanelTemperature,
                            InEnvTemp: this.envMeasureTemperature,
                            InPanelLostPower: this.inPanelTotalLostpower,
                            CalcDeviceLostPower: this.$store.state.totalLostPower_3,
                            ReportDate: this.currentDate2
                        };
                        if (this.$store.state.memberId == 'TestMember') {
                            this.isLoginModalActive = true;
                        }

                        api.panels.addNewProject(project).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.getReportPdf(res.data);

                                var oldProjectId = this.$store.state.projectId;
                                this.updateAcceptLog(oldProjectId, res.data);
                                this.updateCalcDevices(oldProjectId, res.data);
                                this.$store.state.projectId = res.data;

                                this.$store.state.prevProjectId = res.data;
                                this.$buefy.toast.open(this.$lang.ReportPage.Saved);
                            }
                        });
                    }
                } else {
                    const project = {
                        ProjectId: this.$store.state.projectId,
                        MemberId: this.$store.state.memberId,
                        IsCompleted: 1,
                        ProjectName: this.projectName,
                        PanelName: this.panelName,
                        PanelNo: this.panelNo,
                        PanelRatedCurrent: this.panelRatedCurrent,
                        RatedCurrentIndex: this.$store.state.ratedCurrentIndex,
                        TotalEyesNumber: this.totalEyesNumber,
                        EyeNo: this.eyesNumber,
                        PanelType: this.panelType,
                        PanelTypeId: this.$store.state.panelTypeIndex,
                        PanelDimension: this.panelDimension,
                        IP: this.panelIP,
                        Sair: newSair,
                        SurfaceInfo: this.$store.state.surfaceExplanation,
                        VirtualSurface: this.$store.state.virtualSurface,
                        VirtualSurfaceIndex: this.$store.state.virtualSurfaceIndex,
                        VirtualSurfaceOption: this.$store.state.virtualSurfaceOption,
                        SeperationNumber: this.seperationNumber,
                        Ae: this.$store.state.ae_value,
                        Ta: this.envTemperature,
                        Tmax: this.maxTemperature,
                        Altitude: this.altitude,
                        CalcTypeId: this.calcIndex,
                        KnownLostPower: this.knownTotalLostpower,
                        InPanelTemp: this.inPanelTemperature,
                        InEnvTemp: this.envMeasureTemperature,
                        InPanelLostPower: this.inPanelTotalLostpower,
                        CalcDeviceLostPower: this.$store.state.totalLostPower_3,
                        ReportDate: this.currentDate2
                    };
                    // console.log(project);

                    api.panels.updateProject(project).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.ReportPage.Updated);
                            this.getReportPdf(this.$store.state.projectId);
                        }
                    });
                }
            },
            updateCalcDevices(oldProjectId, newProjectId) {
                api.panels.updateCalcTables_1(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_2(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_3(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_4(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_5(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_6(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_7(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
            },
            setCurrentDate() {
                var currDate = new Date();
                var day = String(currDate.getDate()).padStart(2, '0');
                var month = String(currDate.getMonth() + 1).padStart(2, '0');
                var year = currDate.getFullYear();
                this.currentDate = day + "/" + month + "/" + year;
                this.currentDate2 = year + "-" + month + "-" + day;
            },
            setProjectInfos() {
                this.projectName = this.$store.state.projectName;
                this.panelName = this.$store.state.panelName;
                this.panelNo = this.$store.state.panelNo;
                this.panelRatedCurrent = this.$store.state.panelRatedCurrent;
                if (this.$store.state.ratedCurrentIndex == 3) {
                    this.isMultiEyed = true;
                } else {
                    this.isMultiEyed = false;
                }
                this.totalEyesNumber = this.$store.state.totalEyesNumber;
                this.eyesNumber = this.$store.state.eyesNumber;
            },
            setPanelInfos() {
                this.panelType = this.$store.state.panelType;
                this.panelDimension = this.$store.state.panelDimension;
                this.panelIP = this.$store.state.panelIP;
                this.virtualSurface = this.$store.state.surfaceExplanation;
                this.Sair = this.$store.state.Sair;
                this.virtualSurfaceOption = this.$store.state.virtualSurfaceOption;
                this.seperationNumber = this.$store.state.seperationNumber;
                if (this.$store.state.ratedCurrentIndex == 2 || this.$store.state.ratedCurrentIndex == 3) {
                    this.total_Ae = this.$store.state.ae_value;
                    this.aevalues = this.$store.state.ae_valueList;
                } else {
                    this.total_Ae = 'N/A';
                    this.aevalues = ['N/A', 'N/A', 'N/A', 'N/A', 'N/A'];
                }
            },
            setEnvConditions() {
                this.envTemperature = this.$store.state.envTemperature;
                this.maxTemperature = this.$store.state.maxTemperature;
                this.humidity = this.$store.state.humidity;
                this.altitude = this.$store.state.altitude;
            },
            setCalculationDatas() {
                this.knownTotalLostpower = this.$store.state.totalLostPower_1;

                this.inPanelTemperature = this.$store.state.inPanelTemperature;
                this.envMeasureTemperature = this.$store.state.envMeasureTemperature;
                this.inPanelTotalLostpower = this.$store.state.totalLostPower_2;
            },
            updateAcceptLog(oldProjectId, newProjectId) {
                if (this.$store.state.memberId != 'TestMember') {
                    const log = {
                        MemberId: this.$store.state.memberId,
                        ProjectId: newProjectId,
                        OldProjectId: oldProjectId
                    };
                    api.panels.updateAcceptLog(log).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {

                        }
                    });
                }               
            },
            updateAcceptLog_2() {
                if (this.$store.state.memberId != 'TestMember') {
                    const log = {
                        MemberId: this.$store.state.memberId,
                        ProjectId: this.$store.state.projectId,
                        OldProjectId: this.$store.state.projectId
                    };
                    api.panels.updateAcceptLog(log).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {

                        }
                    });
                }               
            },
            openFanCalc() {
                this.$store.isPageOpenedFromStepBar = false;
                router.push('/fan_Calc');
            },
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/results');
            }
        },

    };
</script>