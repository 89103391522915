<template>
    <div>

    </div>
</template>

<script>
    export default {
		name: 'NewLayoutComponent',
        data() {
            return {
            }
        },
        mounted() {              
        },
        methods: {    
        }
    };
</script>
