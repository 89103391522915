<template>
	<b-field horizontal class="mt-5">
		<div>
			<b-button type="is-light" tag="router-link" :to="backToUrl" icon-left="chevron-left"> Geri Dön </b-button>
			<b-button native-type="submit" type="is-success" class="is-pulled-right" icon-right="content-save" :loading="isLoading">
				Kaydet
			</b-button>
		</div>
	</b-field>
</template>

<script>
export default {
	props: {
		isLoading: {
			type: Boolean,
			required: true
		},
		backToUrl: {
			type: String,
			required: true
		}
	}
};
</script>
