<template>
	<footer v-show="isFooterBarVisible" class="footer" :class="{ 'isFixed' : isFooterFixed}">
		<div class="container-fluid">
			<div class="level is-vcentered">
				<div class="level-left">
					<div class="level-item">
						<div class="footer-copyright has-text-white">
							&copy; 2021- {{ year }}&nbsp;
							<span class="tag"> v2.0.1</span>
						</div>
					</div>
				</div>
				<div class="level-right">
					<div class="level-item">
						<div class="footer-copyright has-text-white">
							<div style="padding-top: 1.5rem"></div>
							{{this.$lang.Menus.Warning}}
							<!--<a href="https://www.eaeelektroteknik.com/" target="_blank"> <img style="width: 60px;vertical-align:middle;" src="/img/eae-elektroteknik-logo.png" /></a>-->
							<a href="https://www.eaeelektroteknik.com/" target="_blank" style="color:white"> <b>EAE Elektroteknik A.Ş.</b> </a>
							{{this.$lang.Menus.Warning2}}
							{{this.$lang.Menus.Warning3}}
							<div style="padding-top: 1rem"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'FooterBar',
	data() {
		return {
			year: new Date().getFullYear()
		};
	},
	computed: {
		...mapState(['isFooterBarVisible', 'isFooterFixed'])
	}
};
</script>
